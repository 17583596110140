<template>
  <BoxGroup :col="col" :col-neg="colNeg">
    <Box line="1" v-for="(value, label) in stats" :key="label" :link="links && links[label]" :router-link="routerLinks && routerLinks[label]">
      <Flexy :between="true" :flex="1" :col-neg="statsCol">
        <Txt bold="1">{{label}}</Txt>
        <Txt :mood="getMood(label)">{{value}}</Txt>
      </Flexy>
    </Box>
  </BoxGroup>
</template>

<script>
import Box from "@/components/ui/layout/Box";
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import BoxGroup from "@/components/ui/layout/BoxGroup";
export default {
  name: "StatsBox",
  props: ['stats', 'moods', 'links', 'routerLinks','col','colNeg','statsCol'],
  components: {BoxGroup, Txt, Flexy, Box},
  methods: {
    getMood(label) {

      if (this.moods && this.moods[label]) {
        if (typeof this.moods[label] === "string") return this.moods[label]

        // complex mood settings yay
        if (!isNaN(this.stats[label]*1) && (this.stats[label]*1) > 0 && this.moods[label].positive) return this.moods[label].positive;
        else if (!isNaN(this.stats[label]*1) && (this.stats[label]*1) < 0 && this.moods[label].negative) return this.moods[label].negative;
        else if (!isNaN(this.stats[label]*1) && (this.stats[label]*1) === 0 && this.moods[label].zero) return this.moods[label].zero;
        else if (this.moods[label].mood) return this.moods[label].mood;
      }
      return ''
    }
  }
}
</script>
