<template>
  <Panel>
    <Txt :bold="true">Add Size</Txt>
    <Flexy :flex="1" :full="1">
      <Flexy :flex="2">
        <vSelect v-model="newSize" :options="servingSizes" label="name" :reduce="it => it.id" placeholder="Select Size" />
      </Flexy>
      <Flexy :flex="1">
        <Input class="unwrap" type="number" v-model="newAmount" placeholder="Grams" />
      </Flexy>

    </Flexy>
    <Flexy>
        <Button @click="addSize">Add</Button>
    </Flexy>
    <Txt mood="danger" v-if="existsError">This serving size already exists for this ingredient</Txt>
  </Panel>
</template>

<script>
import Panel from "@/components/ui/layout/Panel";
import Txt from "@/components/ui/utils/Txt";
import Flexy from "@/components/ui/layout/Flexy";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";
export default {
  name: "AddServingSizeForm",
  components: {Button, Input, Flexy, Txt, Panel},
  props: ['servingSizes', 'existingSizes'],

  data() {
    return {
      newSize : '',
      newAmount: '',
      existsError: false
    }
  },
  methods : {
    addSize() {
      if (this.canSubmit) {
        this.$emit('add', this.newSize, this.newAmount)
        this.newSize = this.newAmount = ''
      }
    }
  },
  computed : {
    canSubmit() { return this.newSize && this.newAmount && !this.existsError }
  },
  watch : {
    newSize() {
      if (this.newSize) {
        const size = this.servingSizes.find(it => it.id === this.newSize)
        if (size) this.newAmount = size.amount_g;

        this.existsError = this.existingSizes && this.existingSizes.find(it => it.serving_size_id === this.newSize)
      }
      else {
        this.newAmount = ''
      }
    }
  }
}
</script>
