<template>
  <input :type="inputType" :placeholder="inputPlaceholder" :value="val" @keyup="e => onChange(e)" @change="e => onChange(e)" autocomplete="none" />
</template>
<script>
export default {
  name: "Input",
  props : {
    placeholder: { type: String, default: '' },
    value: { type: [String, Number, Boolean], default: '' },
    password: { type: Boolean },
    number: { type: Boolean },
    checkbox: { type: Boolean },
  },
  data() {
    return {
      val: ''
    }
  },
  mounted() {
    this.val = this.value;
  },
  computed : {
    inputType() {
      if (this.password) return 'password'
      else if (this.number) return 'number'
      else if (this.checkbox) return 'checkbox'
      return 'text'
    },
    inputPlaceholder() {
      return this.placeholder;
    }
  },
  methods : {
    onChange(e) {
      this.$emit('input', e.target.value, e.target)
    }
  },
  watch : {
    value() {
      this.val = this.value
    }
  }
}
</script>
