<template>
  <div :class="className">
    <slot></slot>
  </div>
</template>

<script>


import Flexer from "@/mixins/Flexer";

export default {
  name: "Flexy",
  mixins: [Flexer],
  data() {
    return {
      clsName: 'flexy'
    }
  },
}
</script>
