<template>
  <ul :class="className" ref="list">
    <li v-for="(item ,i) in shownItems" :class="itemClass(i)" :key="i">
      <router-link :to="item.to">{{item.label}}</router-link>
    </li>
    <li v-if="more > 0 && shownItems.length > 0" :class="testing?'test':''" @click="toggleShowMore" ref="more">
      <a>
        <Txt>{{moreLabel || 'More...'}}</Txt>
        <Icon :icon="moreIcon" height="15" width="9" rotate="90" :link="true" />
      </a>
      <NavFloatingMenu v-if="showMore && shownItems.length > 0" :items="hiddenItems" :container="$refs.more" constrain-x="right" />
    </li>
    <li v-show="shownItems.length === 0" @click="toggleShowMore" ref="mini_menu">
      <a>
        <Icon :icon="menuIcon" height="18" width="18" :link="true" />
        <Txt>{{menuLabel || 'Menu'}}</Txt>
      </a>
      <NavFloatingMenu v-if="showMore && shownItems.length === 0" :items="hiddenItems" :container="$refs.mini_menu" constrain-x="right" />
    </li>
  </ul>
</template>

<script>

import Icons from "@/lib/Icons";
import Icon from "@/components/ui/utils/Icon";
import NavFloatingMenu from "@/components/ui/navigation/NavFloatingMenu";
import Txt from "@/components/ui/utils/Txt";

const sleep = (ms) => new Promise(res => setTimeout(() => res(), ms))

export default {
  name: "NavMenu",
  components: {Txt, NavFloatingMenu, Icon},
  props: ['items', 'safetyMargin', 'testSlack', 'menuLabel', 'moreLabel'],
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  data() {
    return {
      moreIcon: Icons.caret,
      menuIcon: Icons.burger,
      more : 0,
      testing : false,

      showMore: false,
    }
  },
  computed: {
    className() {
      let cls = 'nav-menu';
      return cls
    },
    shownItems() {
      return this.items.filter((it,i) => this.items.length-i > this.more)
    },
    hiddenItems() {
      return this.items.filter((it,i) => this.items.length-i <= this.more)
    }
  },
  methods: {

    toggleShowMore() {
      this.showMore = !this.showMore
    },

    itemClass(i) {
      return this.testing && this.items.length-i <= this.more+1 ? 'test':'';
    },
    async onResize() {
      const safetyMargin = this.safetyMargin || 80;
      const testSlack = this.testSlack || 40;
      let widths = this.getWidths()
      let breakDistance = widths.total - safetyMargin - widths.items
      this.testing = true
      while (breakDistance > safetyMargin+testSlack && this.more > 0) {
        this.more--
        await sleep(1)
        widths = this.getWidths()
        breakDistance = widths.total - safetyMargin - widths.items
      }
      this.testing = false
      while (breakDistance < 0) {
        this.more++
        await sleep(1)
        widths = this.getWidths()
        breakDistance = widths.total - safetyMargin - widths.items
      }
    },

    getWidths() {
      const li = this.$refs.list.querySelectorAll('li')
      let items = 0, s;
      for (let l of li) {
        s = getComputedStyle(l)
        items += l.clientWidth + (parseInt(s.marginLeft)) + (parseInt(s.marginRight));
      }

      return {items, total: this.$refs.list.clientWidth }
    },

  }
}
</script>
