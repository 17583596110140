<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :on-update="onUpdate" :on-submit="onSubmit" />
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";

export default {
  name: "Snapshot",
  mixins: [ InstanceView ],
  components: {InstanceDash,},
  data() {
    return {
      type: "Snapshot",
      path: "snapshot",
      fields : [
        Fe.Text("name", "Name"),
        Fe.Textarea("description", "Description"),
      ],
      Create : this.createSnapshot,
      Update : this.updateSnapshot,
      defaultData: { name: "S_", slug: "" },
    }
  },

  async mounted() {
    await this.initInstance('allSnapshots',[!this.allSnapshots.length, this.getSnapshots])
  },
  computed: mapGetters(['allSnapshots']),
  methods: mapActions(['getSnapshots','updateSnapshot','createSnapshot']),
}
</script>
