<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields"
                :on-update="onUpdate" :on-submit="onSubmit"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale">
    <Box slot="sidebar" v-if="instance" title="Markets">
        <TagList :tags="instance.markets" tag-key="market_id" :all-tags="allMarkets" prop="name" secondary="amount_g" secondary-suffix="g" @remove-tag="onRemoveMarket" />
        <AddMarketForm :markets="allMarkets" @add="onAddMarket" />
    </Box>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Api from "@/lib/Api";
import AddMarketForm from "@/components/forms/AddMarketForm";
import TagList from "@/components/ui/lists/TagList";
import InstanceView from "@/mixins/views/InstanceView";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import Box from "@/components/ui/layout/Box";
import Fe from "@/lib/ui/FormElement";

export default {

  name: "ServingSize",
  components: {Box,InstanceDash,TagList, AddMarketForm,},
  mixins: [InstanceView],

  data() {
    return {
      type: "Serving Size",
      path: "serving-size",
      fields : [
        Fe.Text("name", "Name"),
        Fe.Number("amount_g", "Amount (G)"),
      ],
      Create : this.createServingSize,
      Update : this.updateServingSize,
      defaultData: {
        name: "New Serving Size",
        amount_g: 0,
        markets: []
      },
      locale : { get: Api.GetServingSizeLocales, create : Api.CreateServingSizeLocale, update: Api.UpdateServingSizeLocale },
      localeFields: [ {prop: "name", type:"text", label:"Name"}, {prop: "name_plural", type:"text", label:"Plural Form"} ],
    }
  },

  mounted() {
    this.initInstance('allServingSizes',[
      [!this.allServingSizes.length, this.getServingSizes],
      [!this.allMarkets.length, this.getMarkets],
    ])
  },

  computed: mapGetters(['isLoggedIn', 'allServingSizes', 'allMarkets','savedServingSize']),

  methods: {
    ...mapActions(['setLoading','setSuccess','getServingSizes','getMarkets','updateServingSize','createServingSize']),
    onAddMarket(market_id, amount_g) {
      this.instance.markets.push({serving_size_id: this.instance.id, market_id, amount_g })
    },
    onRemoveMarket(id) {
      this.instance.markets = this.instance.markets.filter(it => it.id !== id)
    },
  },
}
</script>
