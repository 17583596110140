<template>
  <h1 :class="className">
    <a v-if="link" :href="link" :class="mood"><slot></slot></a>
    <router-link v-if="routerLink" :to="routerLink"><slot></slot></router-link>
    <slot v-if="!link && !routerLink"></slot>
  </h1>
</template>

<script>
export default {
  name: "Header",
  props: ['mood', 'bold', 'link', 'small', 'routerLink'],
  computed: {
    className() {
      let cls = 'header';
      if (this.bold) cls += ' bold'
      if (this.small) cls += ' small'
      switch (this.mood) {
        case 'primary': cls += ' primary'; break;
        case 'success': cls += ' success'; break;
        case 'warning': cls += ' warning'; break;
        case 'danger': cls += ' danger'; break;
      }
      return cls
    }
  }
}
</script>
