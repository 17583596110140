import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        snapshots : [],
    },
    getters : {
        allSnapshots: state => state.snapshots,
    },
    actions : {
        async deleteSnapshot({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteSnapshot, id))
            return await commit('deleteSnapshot', id)
        },
        async getSnapshots({commit}) {
            const snapshots = await ApiRequest(ApiCall(Api.ListSnapshots));
            return await commit('setSnapshots', snapshots);
        },
        async updateSnapshot({commit, dispatch}, [snapshot, id]) {
            const res = await ApiRequest(ApiCall(Api.UpdateSnapshot, snapshot, id))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                snapshot.id = res.insertId || res.id
                await dispatch('setSuccess', 'Snapshot Updated')
                return await commit('saveSnapshot', [snapshot, id])
            }
        },
        async createSnapshot({commit, dispatch}, snapshot) {
            const res = await ApiRequest(ApiCall(Api.CreateSnapshot, snapshot))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                snapshot.id = res.insertId || res.id
                await dispatch('setSuccess', 'Snapshot Created')
                return await commit('saveSnapshot', [snapshot, null])
            }
        },
    },
    mutations : {
        setSnapshots: (state, snapshots) => state.snapshots = snapshots,
        saveSnapshot: (state, [snapshot, id]) => {
            if (id) {
                state.snapshots = state.snapshots.map(it => it.id === snapshot.id ? snapshot : it)
            }
            else {
                state.snapshots = [...state.snapshots, snapshot];
            }
            return state
        },
        deleteSnapshot(state, id) {
            state.snapshots = state.snapshots.filter(it => it.id !== id)
        },
    }
}
