import Icons from "@/lib/Icons";
import Fe from "@/lib/ui/FormElement";
import {mapGetters, mapActions} from "vuex";

export default {

    data() {
        return {
            ingredientKey: "unknown_type_id",
            dynamic_key: -1,

            ingredientFields : [
                { prop: "serving_amount", format : (amt, item) => item.is_dynamic && item.max>amt ? amt+' - '+item.max : amt > 1 ? amt+'x':'', width: 25, bold: (amt, item) => item.is_dynamic && item.max>amt },
                { prop: "serving_size_id", above: 1, width: {2: 100, 3: 120, 4: 200, 5: 200}, format : (id) => { let s = this.allServingSizes.find(it => it.id === id); return s ? s.name:'' } },
                { prop: "ingredient", format:(ing) => ing.name, primary: true },
                { prop: "is_fixed", type:'icon', format:(ing) => ing?Icons.pushPin:'', },
                { prop: "is_locked", type:'icon', format:(ing) => ing?Icons.lock:'', },
                { prop: "is_dynamic", type:'icon', format:(a, item) => item.is_dynamic?Icons.check:'' },
                { prop: "gap", type: 'badge', if:(a, item)=> !!item.is_dynamic && item.max > item.amount_g },
                { prop: "amount_g", format: (a, item) => this.formatAmount(a, item), bold: (amt, item) => item.is_dynamic && item.max },
                { actions: [
                        { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete", confirmation: (ing) => "Are you sre you want to delete "+ing.ingredient.name+'?' },
                        { mood: "primary", icon: Icons.pencil, action: "edit", tooltip: "Edit" }
                    ]}
            ],
            addIngredientFields : [
                Fe.Select('ingredient_id','Ingredient',null,'Select Ingredient','name','ingredients'),
                { ...Fe.Select('serving_size_id','Serving Size', null, 'Select Serving Size','name','servingSizes'), if : (item) => !!item.ingredient_id },
                { ...Fe.Number('serving_amount','Serving Amount', null, 'Serving Amount'), if : (item) => !!item.ingredient_id && item.serving_size_id !== null },
                { ...Fe.Checkbox('is_dynamic','Dynamic' ), if : (item) => !!item.ingredient_id && item.serving_size_id !== null },
            ],

            selectedIngredient: null,
            selectedIngredientIndex : null,
        }
    },

    async mounted() {
        if (!this.allServingSizes.length) await this.getServingSizes()
        if (!this.allIngredients.length) await this.getIngredients()

    },

    computed : {
        ...mapGetters(['allServingSizes','allIngredients']),
        sortedIngredients() {
            const ingredients = this.instance ? [...this.instance.ingredients] : []
            return ingredients.sort((a, b) => a.sort < b.sort?-1:1)
        },

        dynIndex() {
            if (this.dynamic_key > -1) return this.dynamic_key
            if (!this.instance) return -1;
            for (let i = 0; i < this.instance.ingredients.length; i++) {
                if (this.instance.ingredients[i].is_dynamic) {
                    this.dynamic_key = i;

                    return i;
                }
            }
            return -1
        }
    },

    methods : {
        ...mapActions(['getServingSizes','getIngredients']),

        onNewIngredientUpdate(values) {
            this.newIngredient = values;
        },

        onAddIngredient(props) {
            const entry = {...props, id: props.ingredient_id, sort: this.selectedIngredient ? this.selectedIngredient.sort : this.instance.ingredients.length, [this.ingredientKey]: this.instance.id};
            let key = this.selectedIngredientIndex !== null ? this.selectedIngredientIndex : this.instance.ingredients.length,
                isDynamic = entry.is_dynamic;
            if (this.selectedIngredientIndex !== null) { // edit existing
                this.$set(this.instance.ingredients, this.selectedIngredientIndex, entry)
                this.selectedIngredient = this.selectedIngredientIndex = null;
            }
            else { // insert new
                this.instance.ingredients.push(entry)
            }
            for (let i = 0; i < this.instance.ingredients.length; i++) {
                if (isDynamic && this.instance.ingredients[i].is_dynamic && i !== key) {
                    this.$set(this.instance.ingredients, i, {...this.instance.ingredients[i], is_dynamic: false})
                }
            }

            if (isDynamic) this.dynamic_key = key
        },

        onDeleteIngredient(ingredient) {
            this.instance.ingredients = this.instance.ingredients.filter(it => (it.id && it.id !== ingredient.id))
        },

        onSortIngredient(oldIndex, newIndex) {
            const ings = [...this.sortedIngredients], ing = ings.splice(oldIndex, 1);
            ings.splice(newIndex, 0, ing[0]);

            for (let i = 0; i < ings.length; i++) {
                ings[i].sort = i+1;
            }
            this.instance.ingredients = ings;
        },

        editIngredient(ingredient) {
            const index = this.instance.ingredients.findIndex(it => it.id === ingredient.id)

            if (index === this.selectedIngredientIndex) {
                this.selectedIngredient = this.selectedIngredientIndex = null;
            } else {
                this.selectedIngredient = {...ingredient};
                this.selectedIngredientIndex = index
            }
        },

        formatAmount(a, item) {

            const isDynamic = this.dynIndex > -1,

                maxFactor = isDynamic ?
                    this.instance.ingredients[this.dynIndex].max /
                        this.instance.ingredients[this.dynIndex][this.instance.ingredients[this.dynIndex].serving_size_id ?'serving_amount':'amount_g']
                    : 1;

            const
                maxSize = maxFactor * item.amount_g * item.serving_amount,
                absMax = item.max ? (item.serving_size_id? item.max * item.amount_g : item.max) : Infinity,
                hitMax = absMax < maxSize


            return (a*item.serving_amount)+' '+
                (item.ingredient && item.ingredient.unit_type||'g') +
                (isDynamic && !item.is_fixed ?
                    ' - ' + Math.min(absMax, maxSize).toFixed(2)*1 +' '
                    +(item.ingredient && item.ingredient.unit_type||'g')
                    +(hitMax ? ' (max)' : '')
                    :  '')
        }
    }
}
