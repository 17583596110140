<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields"
                :on-update="onUpdate" :on-submit="onSubmit"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale" />
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";
import Api from "@/lib/Api";

export default {
  name: "MealType",
  mixins: [ InstanceView ],
  components: {InstanceDash },
  data() {
    return {
      type: "Meal Type",
      path: "meal-type",
      fields : [
        Fe.Text("name", "Name"),
        Fe.Text("slug", "Slug"),
      ],
      Create : this.createMealType,
      Update : this.updateMealType,
      defaultData: { name: "New Meal Type", slug: "" },
      locale : { get: Api.GetMealTypeLocales, create : Api.CreateMealTypeLocale, update: Api.UpdateMealTypeLocale },
      localeFields: [ {prop: "name", type:"text", label:"Name"} ],
    }
  },

  async mounted() {
    await this.initInstance('allMealTypes',[!this.allMealTypes.length, this.getMealTypes])
  },

  computed: mapGetters(['allMealTypes']),
  methods: mapActions(['getMealTypes','createMealType','updateMealType']),
}
</script>
