<template>
  <Panel title="Log In">
    <form @submit="onSubmit">
      <Flexy :even="true">
        <Input placeholder="Username" v-model="username" />
        <Input placeholder="Password" v-model="password" :password="true" />
        <Button @click.native="onSubmit">Login</Button>
      </Flexy>
      <Txt mood="danger" v-if="loginError">Error: {{loginError}}</Txt>
    </form>
  </Panel>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import Panel from "@/components/ui/layout/Panel";
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";

export default {
  name: "LoginForm",
  components: {Button, Input, Txt, Flexy, Panel},

  computed: {
    ...mapGetters(['loginError']),
  },

  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(['login', 'logout']),
    async onSubmit(e) {
      e.preventDefault()
      await this.login([this.username, this.password])
    }
  }
}
</script>
