<template>
    <Container>
      <ConfirmModal v-if="userRoleForDeletion" item-type="User Role" :item-name="userRoleForDeletion.name" :confirm="confirmDeleteUserRole" :cancel="cancelDeleteUserRole" />
      <FilteredItemList title="User Roles" :fields="fields" :items="allUserRoles" :on-click="goto" :search="true" @delete="askToDeleteUserRole" :actions="{'Add User Role': addNewUserRole }" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "UserRoles",
  components: {Container, ConfirmModal, FilteredItemList },
  data() {
    return {
      userRoleForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", primary: true},
        {label: "Permissions", prop: "permissions", format: (v) => v instanceof Array && v.length },
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
        ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allUserRoles']),
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allUserRoles.length, this.getUserRoles]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getUserRoles','setLoading','deleteUserRole']),
    goto(userRole) {
      this.$router.push('/role/'+userRole.id)
    },
    addNewUserRole() {
      this.$router.push('/role/new')
    },
    askToDeleteUserRole(userRole) {
      this.userRoleForDeletion = userRole;
    },
    cancelDeleteUserRole() { this.userRoleForDeletion = null },
    confirmDeleteUserRole() {
      this.deleteUserRole(this.userRoleForDeletion.id)
      this.userRoleForDeletion = null
    }
  },

}
</script>
