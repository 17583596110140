<template>
    <Container>
      <ConfirmModal v-if="mealStyleForDeletion" item-type="Meal Style" :item-name="mealStyleForDeletion.name" :confirm="confirmDeleteMealStyle" :cancel="cancelDeleteMealStyle" />
      <FilteredItemList title="Meal Styles" :fields="fields" :items="allMealStyles" :on-click="goto" :search="true" @delete="askToDeleteMealStyle" :actions="{'Add Meal Style': addNewMealStyle }" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "MealStyles",
  components: {Container, FilteredItemList, ConfirmModal },
  data() {
    return {
      mealStyleForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: 'str', width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allMealStyles.length, this.getMealStyles]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMealStyles']),
  },

  methods: {
    ...mapActions(['getMealStyles','setLoading','deleteMealStyle']),
    goto(mealStyle) {
      this.$router.push('/meal-style/'+mealStyle.id)
    },
    addNewMealStyle() {
      this.$router.push('/meal-style/new')
    },
    askToDeleteMealStyle(mealStyle) {
      this.mealStyleForDeletion = mealStyle;
    },
    cancelDeleteMealStyle() { this.mealStyleForDeletion = null },
    confirmDeleteMealStyle() {
      this.deleteMealStyle(this.mealStyleForDeletion.id)
      this.mealStyleForDeletion = null
    }
  },
}
</script>
