<template>
  <Modal title="Are you sure?" :cancel="cancel" :actions="{'Confirm': confirm}">
    Do you really want to delete the {{itemType}} <b>{{itemName}}</b>?
  </Modal>
</template>

<script>
import Modal from "@/components/ui/layout/Modal";
export default {
  name: "ConfirmModal",
  components: {Modal},
  props: ['itemType', 'itemName', 'confirm', 'cancel']
}
</script>
