import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        servingSizes : [],
        savedServingSize: null
    },
    getters : {
        allServingSizes: state => state.servingSizes,
        savedServingSize: state => state.savedServingSize,
    },
    actions : {
        async deleteServingSize({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteServingSize, id))
            return await commit('deleteServingSize', id)
        },
        async getServingSizes({commit}, locale = null) {
            let servingSizes;
            if (locale)
                servingSizes = await ApiRequest(ApiCall(Api.GetServingSizeLocales, locale));
            else
                servingSizes = await ApiRequest(ApiCall(Api.ListServingSizes));

            return await commit('setServingSizes', servingSizes);
        },
        async updateServingSize({commit, dispatch}, [servingSize, id]) {
            const res = await ApiRequest(ApiCall(Api.UpdateServingSize, servingSize, id))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                servingSize.id = res.id
                await dispatch('setSuccess', 'Serving Size Updated')
                return await commit('saveServingSize', [servingSize, id])
            }
        },
        async createServingSize({commit, dispatch}, servingSize) {
            const res = await ApiRequest(ApiCall(Api.CreateServingSize, servingSize))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                servingSize.id = res.insertId
                await commit('saveServingSize', [servingSize, null])
                await dispatch('setSuccess', 'Serving Size Created')
            }
            return servingSize
        },

    },
    mutations : {
        setServingSizes: (state, servingSizes) => state.servingSizes = servingSizes,
        saveServingSize: (state, [servingSize, id]) => {
            state.savedServingSize = servingSize
            if (id) {
                state.servingSizes = state.servingSizes.map(it => it.id === servingSize.id ? servingSize : it)
            }
            else {
                state.servingSizes = [...state.servingSizes, servingSize];
            }
            console.log(state.servingSizes)
            return state
        },
        deleteServingSize(state, id) {
            state.servingSizes = state.servingSizes.filter(it => it.id !== id)
        },
    }
}
