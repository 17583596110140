function mealNutrition(meal, raw = false, ingDb, fullDb = false, maxRange = false) {
    const meta = ['name','alternative_group','created_at','id','updated_at','tags','icon', 'instructions','serving_sizes'];
    let ingredients = [];

    if (meal.ingredients && meal.ingredients instanceof Array) ingredients = meal.ingredients;
    else if (meal instanceof Array) ingredients = [...meal]

    if (meal.recipes && meal.recipes.length > 0) {
        for (let r of meal.recipes) {
            if (r.recipe && r.recipe.ingredients) {
                ingredients = [...ingredients, ...r.recipe.ingredients]
            }
        }
    }

    const nutrition = {};
    let theIng, amount;
    if(!(ingredients instanceof Array)) console.log(ingredients)
    for (let ing of ingredients) {
        theIng = null;
        if (!ing || ing.inactive) continue;
        if (ingDb) {
            if (fullDb) {
                theIng = ingDb.find(it => it.id === ing.ingredient_id || (ing.ingredient && it.id === ing.ingredient.id));
            }
            else {
                theIng = ingDb.find(it => it.ingredient_id === ing.ingredient_id || (ing.ingredient && (ing.ingredient.ingredient_id && it.ingredient_id === ing.ingredient.ingredient_id || it.ingredient_id === ing.ingredient.id)));
            }
        }
        if (!theIng) continue;
        if (theIng.ingredient) theIng = theIng.ingredient;
        for (let nut in theIng.nutrition) {
            if (meta.indexOf(nut) > -1) continue;
            if (!nutrition[nut]) nutrition[nut] = 0;
            if (maxRange) {
                let max = ing.max ? (ing.serving_size_id ? ing.amount_g * ing.max : ing.max) : (ing.serving_size_id ? ing.amount_g * ing.serving_amount : ing.serving_amount)
                nutrition[nut] += ing.ingredient.nutrition[nut] / 100 * max
            }
            else {
                amount = ing.max ? Math.min(ing.amount_g * (ing.serving_size_id ? ing.serving_amount : 1), ing.serving_size_id ? ing.max * ing.amount_g : ing.max) : ing.amount_g * (ing.serving_size_id ? ing.serving_amount : 1)
                nutrition[nut] += theIng.nutrition[nut] * amount / 100;
            }
        }
    }

    if (!raw) for (let i in nutrition) nutrition[i] = parseFloat(nutrition[i]).toFixed(2)*1;
    return nutrition;
}

function variationMap(ingredients, index, f) {
    return ingredients.map(ing => {
        if (ing.is_dynamic) {
            if (ing.serving_size_id) return {...ing, serving_amount: index}
            else return {...ing, amount_g: Math.round(ing.amount_g*f)}
        }
        else if (ing.serving_size_id) return {...ing, serving_amount: ing.is_fixed ? ing.serving_amount : Math.round(ing.serving_amount*f)}
        else return {...ing, amount_g: ing.is_fixed ? ing.amount_g : Math.round(ing.amount_g*f)}
    })
}

function recipeVariations(ingredients) {
    let dyn = null, min, max, gap, f = 1;
    for (let ing of ingredients) {
        if (ing.is_dynamic) {
            dyn = ing;
            min = ing.serving_size_id ? ing.serving_amount : ing.amount_g;
            max = ing.max;
            gap = ing.gap;
            break
        }
    }
    if (dyn === null || min === max || !max) return [ingredients]

    const res = [ingredients]
    if (dyn.serving_size_id) {
        for (let s = min*1+1; s <= max; s++) {
            f = s / min
            res.push(variationMap(ingredients, s, f))
        }
    }
    else if (gap) {
        if (gap.indexOf(',') > -1) {
            gap = gap.split(/,/g).map(it => it*1)
            for (let s of gap) {
                f = s / min
                res.push(variationMap(ingredients, s, f))
            }
        }
        else {
            gap = gap.trim()*1; min = min*1;
            for (let s = min+gap; s < max; s+= gap) {
                f = s / min
                res.push(variationMap(ingredients, s, f))
            }
        }
        f = max / min;
        res.push(variationMap(ingredients, max, f))
    }
    return res;
}

module.exports = {
    mealNutrition,
    recipeVariations,
}
