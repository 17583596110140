<template>
  <Container>
    <LoginForm v-if="!isLoggedIn" />

    <Panel title="Summary" v-if="isLoggedIn">
      <Flexy :even="true" :full="3">
        <Box title="Foods Database">
          <StatsBox :stats="generalStats" :moods="generalMoods" :router-links="generalLinks" :col-neg="{1:1}" />
        </Box>
        <Box title="Snapshots Information">
          <StatsBox :stats="snapshotStats" :moods="snapshotMoods" :router-links="snapshotLinks" />
        </Box>
      </Flexy>
    </Panel>

    <Panel title="Foods Overview" v-if="isLoggedIn">
      <Flexy :even="true" :full="2" :auto-flex="true">
        <Box title="Problems" :title-value="totalProblems" title-mood="danger" :badge-title="true">
          <StatsBox :stats="problems" :moods="problemsMoods" :router-links="problemsLinks" />
        </Box>
        <Box title="Actions">
          <Flexy :wraps="true">
            <Button>Download Ingredients</Button>
            <Button>Download Recipes</Button>
          </Flexy>
        </Box>
      </Flexy>
    </Panel>

  </Container>
</template>

<script>
import Box from "@/components/ui/layout/Box";
import Container from "@/components/ui/layout/Container";
import Panel from "@/components/ui/layout/Panel";
import Flexy from "@/components/ui/layout/Flexy";
import StatsBox from "@/components/ui/stats/StatsBox";
import Button from "@/components/ui/form/Button";
import LoginForm from "@/components/forms/LoginForm";
import {mapActions, mapGetters} from "vuex";
import {actionIf, delayedIfLoading} from "@/lib/Async";
export default {
  name: "Home",
  props: [],
  components: {LoginForm, Button, StatsBox, Flexy, Panel, Container, Box},

  data() {
    return {
      dayWeekMonthPeriod: ['Today','This Week','Month','Year'],
      generalMoods: { "Ingredients" : "success", "Recipes" : "success", },
      generalLinks: { "Ingredients" : "/ingredients", "Recipes" : "/recipes" },

      snapshotStats : {"Latest Snapshot": "S4", "Latest Snapshot Date":"Oct 2, 2021 8:32pm", "Total Snapshots": 4},
      snapshotMoods: { "Snapshots" : { positive: "danger", zero: "success" } },
      snapshotLinks: { "Total Snapshots" : "/snapshots" },
      problemsMoods: { "Broken Ingredients in Recipes" : { positive: "danger", zero: "success" } },
      problemsLinks: { "Broken Ingredients in Recipes" : "/problems/2"},
      salesLegend : { income: "Income", refunds: "Refunds", total: "Total" },
      salesMoods : { income: "success", refunds: "danger" },
      subscribersLegend : { subscribers: "New Subscribers", cancels: "Cancellations" },
      subscribersMoods : { subscribers: "success", cancels: "danger" }
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
        [!this.allIngredients.length, this.getIngredients],
        [!this.allRecipes.length, this.getRecipes],
          ]
        ), this.setLoading,
        () => this.isLoggedIn, () => { console.log("Not logged in") })
  },

  computed : {
    ...mapGetters(['isLoggedIn','allIngredients','allRecipes']),
    generalStats() {
      return {
      "Ingredients": this.allIngredients && this.allIngredients.length,
      "Recipes": this.allRecipes && this.allRecipes.length,
      }
    },
    problems() {
      let brokenInRecipes = 0
      if (this.allRecipes) for (let r of this.allRecipes) {
        for (let ing of r.ingredients) { if (!ing.ingredient || !ing.ingredient.id) brokenInRecipes++ }
      }
      const p = {"Broken Ingredients in Recipes": brokenInRecipes};
      return p
    },

    totalProblems() {
      let total = 0;
      for (let prob of Object.keys(this.problems)) total += this.problems[prob]
      return total;
    }
  },

  methods: {
    ...mapActions(['setLoading','getIngredients','getMeals','getRecipes'])
  }

}
</script>
