<template>
  <div class="tag-form">
    <Content :between="true">
      <v-select :taggable="multi" :multiple="multi" v-model="newTag" :options="tags" :label="label?label:'tag'" :reduce="it => it.id" :placeholder="placeholder?placeholder:'Select a Tag'" />
      <Button class="square" @click="addTag">Add</Button>
    </Content>
  </div>
</template>

<script>
import Button from "@/components/ui/form/Button";
import Content from "@/components/ui/layout/Content";
export default {
  name: "AddTagForm",
  components: {Content, Button},
  props: ['tags','label', 'placeholder','multi'],
  data() {
    return {
      newTag : ''
    }
  },
  methods : {
    addTag() {
      if (this.newTag) {
        this.$emit('add', this.newTag)
        this.newTag = ''
      }
    }
  }
}
</script>
