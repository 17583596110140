<template>
  <Content :class="clsName" v-if="field && show">
    <Txt class="label" v-if="labelBefore">{{field.label}}</Txt>
    <component :is="tagName" v-bind="tagProps" v-on="tagEvents" />
    <Txt class="label" v-if="labelAfter">{{field.label}}</Txt>
  </Content>
</template>

<script>
import Content from "@/components/ui/layout/Content";
import Input from "@/components/ui/form/Input";
import Textarea from "@/components/ui/form/Textarea";
import vSelect from "vue-select";
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "FormField",
  components: {Txt, Content},
  props: {
    noLabels: Boolean,
    field : {type: Object},
    value : {type: [String, Number, Object, Array, Boolean] },
    meta : {type: Object },
    show : {type: Boolean, default: true},
    clearable : {type: Boolean, default: true},
    filterOptions: { type: Function, default: null},
  },

  computed : {

    clsName() {
      let cls = 'form-field'
      switch (this.field.type) {
        case 'checkbox': cls += ' horizontal centered'
      }

      return cls;
    },

    tagName() {
      switch (this.field.type) {
        case 'select': return vSelect;
        case 'textarea': return Textarea;
        default: return Input
      }
    },
    tagEvents() {
      const events = {}
      switch (this.field.type) {
        case 'checkbox':

          events.input = (value,el) => {
            this.doChange(!!el.checked)
          }
          break;
        default:
          events.input = (value) => this.doChange(value);
          break;
      }
      return events;
    },

    tagProps() {
      const props = {}
      switch (this.field.type) {
        case 'number':
          props.type = 'number'
          props.placeholder = this.field.placeholder
          props.value = this.value
          break
        case 'password':
          props.type = 'password'
          props.placeholder = this.field.placeholder
          props.value = this.value
          break
        case 'text':
          props.type = 'text'
          props.placeholder = this.field.placeholder
          props.value = this.value
          break
        case 'textarea':
          props.placeholder = this.field.placeholder
          props.value = this.value
          break
        case 'checkbox':
          props.type = 'checkbox'
          props.checked = !!this.value
          break
        case 'select':
          props.placeholder = this.field.placeholder
          props.label = this.field.labelKey
          props.reduce = (it) => this.field.propKey ? it[this.field.propKey] : it.id
          props.value = this.value
          props.clearable = this.clearable
          props.options = this.meta ? this.meta[this.field.metaGroup] : []
          if (typeof this.filterOptions === "function") {
            props.options = this.filterOptions(props.options)
            if (typeof props.options === "function") props.options = props.options()
          }
          break
      }

      return props;
    },
    labelBefore() {
      switch (this.field.type) {
        case 'boolean':
        case 'checkbox':
          return false
        default: return !this.noLabels
      }
    },
    labelAfter() {
      switch (this.field.type) {
        case 'checkbox':
          return true
        default: return false
      }
    }
  },

  methods : {
    doChange(val) {
      this.val = val;
      this.$emit('change', val);
    },
  },

}
</script>
