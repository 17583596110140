<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :on-update="onUpdate" :on-submit="onSubmit" :meta="{roles: allUserRoles}" :title-prop="titleProp" />
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";

export default {

  name: "User",
  mixins: [InstanceView],
  components: {InstanceDash },

  data() {
    return {
      type: "User",
      path: "user",
      titleProp: "username",
      fields : [
        Fe.Text("username", "Username"),
        Fe.Password("password", "Password", null, "Enter a new password"),
        Fe.Select("role_id", "Role", null, "","name","roles"),
      ],
      Create : this.createUser,
      Update : this.updateUser,
      defaultData: { username: "New User", password: "", role_id: 0 },
    }
  },

  async mounted() {
    await this.initInstance('allUsers',[
      [!this.allUsers.length, this.getUsers],
      [!this.allUserRoles.length, this.getUserRoles]
    ])

  },

  computed: mapGetters(['allUsers', 'allUserRoles','savedUser']),
  methods: mapActions(['getUsers','getUserRoles', 'updateUser','createUser']),

}
</script>
