<template>
  <div class="navbar">
    <div class="success-bar" v-if="success">{{success}}</div>
    <router-link to="/">
      <Flexy>
        <Icon :icon="Icons.taco" height="22" width="33" :loading="isLoading" />
        <h1>Fito Foods Admin</h1>
      </Flexy>
    </router-link>
    <NavMenu :items="links" v-if="isLoggedIn" />
    <ThemeSwitcher />
  </div>
</template>

<script>
import NavMenu from "@/components/ui/navigation/NavMenu";
import Icons from '@/lib/Icons';

import {mapGetters} from "vuex";
import Icon from "@/components/ui/utils/Icon";
import Flexy from "@/components/ui/layout/Flexy";
import ThemeSwitcher from "@/components/ui/navigation/ThemeSwitcher";
export default {
  name: "Navbar",
  components : {ThemeSwitcher, Flexy, Icon, NavMenu},
  props : ['links'],
  data() {
    return {
      Icons
    }
  },
  computed : {
    ...mapGetters(['isLoggedIn','isLoading','success']),
  }

}
</script>
