export const Breakpoints = {
    mobile: 576,
    tablet: 768,
    wide: 1200,
    wider: 1480,
    widest: 999999
}

export function propDeep(item, propertyName, setValue = undefined) {
    if (typeof item === "undefined") return;
    let c;
    if (setValue !== undefined) c = item
    let p = propertyName.split(/\./g)
    for (let i = 0; i < p.length; i++) {
        if (typeof item[p[i]] !== "undefined") {
            if (setValue !== undefined && i >= p.length-1) {
                item[p[i]] = setValue
                return c
            }
            item = item[p[i]]
        }
        else {
            if (setValue !== undefined && i >= p.length-1) {
                item[p[i]] = setValue
                return c
            }
            else return;
        }
    }
    return setValue !== undefined ? c : item;
}

export function breakpointClassNames(keys, prefix = '', booleanAdd = false) {
    let cls = '';
    for (let k in keys) {
        if (booleanAdd) {
            if (keys[k]) {
                if (cls) cls += ' '; cls += prefix+k
            }
        } else {
            if (cls) cls += ' '; cls += prefix+k+'_'+keys[k]
        }
    }
    return cls.trim()
}

export function objNotEmpty(obj) {
    return typeof obj !== 'string' && obj && Object.keys(obj).length > 0
}

export function getListValues(items, fields) {
    if (!fields) return items;

    function val(item, field, format) {
        let v, p = field.prop.split(/\./g), res = item;
        while (p.length > 0) {
            v = p.shift();
            res = format ? format(res[v], res) : res[v]
        }
        if (typeof res === 'string' && field.shorten && res.length > field.shorten) return res.substr(0, field.shorten)+'...'
        return res;
    }

    let res = [], o;
    for (let item of items) {
        o = {};
        for (let field in fields) {
            o[fields[field].target?fields[field].target:fields[field].prop] = val(item, fields[field], fields[field].format);
        }
        res.push({...item, ...o})
    }
    return res;
}
