<template>
  <Content :wraps="true">
    <Badge v-for="tag in tags" :key="tag.tag_id">
      <span>{{tagValue(tag)}}</span>
      <b v-if="secondary" class="tag-secondary">{{tag[secondary]}}{{secondarySuffix||''}}</b>
      <Icon v-if="!passive" :icon="deleteIcon" mood="danger" :interactive="true" height="11" width="11" @click="() => removeTag(tag.tag_id || tag.id)" />
    </Badge>
  </Content>
</template>

<script>
import Badge from "@/components/ui/utils/Badge";
import Icons from "@/lib/Icons";
import Icon from "@/components/ui/utils/Icon";
import Content from "@/components/ui/layout/Content";
export default {
  name: "TagList",
  components: {Content, Icon, Badge},
  props : ['allTags', 'tagKey', 'tags', 'prop','secondary','secondarySuffix','passive'],
  data() {
    return {
      deleteIcon: Icons.x
    }
  },
  methods : {
    removeTag(value) {
      this.$emit('remove-tag', value)
    },
    tagValue(t) {
      const tag = this.allTags.find(it => it.id === t[this.tagKey]);
      if (tag) return tag[this.prop]
    },
  }
}
</script>
