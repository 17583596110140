import Vue from 'vue'
import App from './App.vue'
import store from './store';
import VueRouter from 'vue-router';

import routes from "@/routes";
import vSelect from "vue-select";

const router = new VueRouter({
  mode: 'hash',
  routes,
});

Vue.use(VueRouter);
Vue.component('vSelect', vSelect)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
