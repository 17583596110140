import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        meals : [],
    },
    getters : {
        allMeals: state => state.meals,
    },
    actions : {
        async getMeals({commit}) {
            const meals = await ApiRequest(ApiCall(Api.ListMeals));
            return await commit('setMeals', meals);
        },
        async updateMeal({commit}, [meal, id]) {
            await ApiRequest(ApiCall(Api.UpdateMeal, meal, id))
            return await commit('saveMeal', [meal, id])
        },
        async createMeal({commit}, meal) {
            const res = await ApiRequest(ApiCall(Api.CreateMeal, meal))
            meal.id = res.insertId
            return await commit('saveMeal', [meal, null])
        },
        async deleteMeal({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteMeal, id))
            return await commit('deleteMeal', id)
        },
    },
    mutations : {
        setMeals: (state, meals) => state.meals = meals,
        saveMeal: (state, [meal, id]) => {
            if (id) {
                state.meals = state.meals.map(it => it.id === meal.id ? meal : it)
            }
            else {
                state.meals = [...state.meals, meal];
            }
            return state
        },
        deleteMeal(state, id) {
            state.meals = state.meals.filter(it => it.id !== id)
        },
    }
}
