<template>
  <div class="form-group">
    <Txt :bold="true" v-if="title">{{title}}</Txt>
    <div :class="'form-group-inner ' + cls">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "FormGroup",
  components: {Txt},
  props: {
    title: String,
    cls: String,
  },
}
</script>
