import burger from '@/assets/icons/burger.svg'
import caret from '@/assets/icons/caret.svg'
import check from '@/assets/icons/check.svg'
import drag from '@/assets/icons/drag.svg'
import groceries from '@/assets/icons/groceries.svg'
import taco from '@/assets/icons/taco.svg'
import pushPin from '@/assets/icons/push-pin.svg'
import x from '@/assets/icons/x.svg'
import lock from '@/assets/icons/lock.svg'
import pencil from '@/assets/icons/pencil.svg'

export default {
    burger : {icon: burger, colorProp: 'stroke'},
    caret : {icon: caret, colorProp: 'stroke'},
    check : {icon: check, colorProp: 'fill'},
    drag : {icon: drag, colorProp: 'fill'},
    groceries : {icon: groceries, colorProp: 'fill'},
    pushPin : {icon: pushPin, colorProp: 'fill'},
    pencil : {icon: pencil, colorProp: 'fill'},
    lock : {icon: lock, colorProp: 'fill'},
    taco : {icon: taco, colorProp: 'fill'},
    x : {icon: x, colorProp: 'fill'},
}
