import {mapActions, mapGetters} from "vuex";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import {ApiCall, ApiRequest} from "@/lib/Api";

export default {

    data() {
        return {
            // override these:
            type: '(Instance)',
            path: 'unknown-path',
            fields : [],
            async Create() {},
            async Update() {},
            defaultData : {},
            initLoad : [],
            collection : [],
            locale : { get: '', create : '', update : '', }, // Api.endpoints
            localeFields : [],

            // these should be managed here:
            instanceId : null,
            instance : null,
            locales: null,
            collectionProp : null,
        }
    },

    computed : {
        ...mapGetters(['allLocales','isLoggedIn']),
    },

    methods : {
        ...mapActions(['setLoading','setSuccess','getLocales']),

        async onSubmit() {
            await this.setLoading(true);
            if (this.instance.id) { //update
                await this.Update([this.instance, this.instance.id])
            }
            else { // create new
                await this.Create(this.instance);
                if (this.instance.id) {
                    await this.$router.push('/'+this.path+'/'+this.instance.id)
                    this.instance = {...this.instance }
                    this.collection = this[this.collectionProp]
                    await this.initData()
                }
            }
            await this.setLoading(false);
            await this.setSuccess(this.type+" saved");
        },

        onUpdate(values) {
            this.instance = {...this.instance, ...values};
        },

        async initInstance(collectionProp, loadIf) {
            await delayedIfLoading(!this.isLoggedIn, actionIf(loadIf), this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
            this.collection = this[collectionProp];
            this.collectionProp = collectionProp;
            await this.initData()
        },

        async initData() {
            this.instance = !isNaN(this.$route.params.id*1) ? this.collection.find(it => it.id === this.$route.params.id*1) :
                { ...this.defaultData }
            if (this.instance && this.instance.id && this.locale.get) {
                await actionIf([!this.allLocales.length, this.getLocales])()
                await this.getInstanceLocales()
            }
        },

        async getInstanceLocales() {
            if (this.instance.locales) {
                this.locales = this.instance.locales;
            }
            else {
                this.locales = await ApiRequest(ApiCall(this.locale.get, this.instance.id))
            }
        },

        async createLocale(instanceId, locale_id, data) {
            return await ApiRequest(ApiCall(this.locale.create, { locale_id, ...data },  instanceId))
        },
        async updateLocale(instanceId, locale_id, data) {
            return await ApiRequest(ApiCall(this.locale.update, data,  instanceId+'/'+locale_id))
        },

        hasLocale(locale) {
            return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
        },

        getLocale(locale) {
            return this.locales.find(it => it.locale_id === locale.id)
        },

        async setLocale(data, locale_id) {
            if (data.id) {
                await this.updateLocale(this.instance.id, locale_id, data)
                this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, ...data} : it)
            }
            else {
                const res = await this.createLocale(this.instance.id, locale_id, data)
                this.locales.push({id: res.insertId, locale_id, ...data})
            }
        }
    }
}
