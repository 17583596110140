<template>
    <Container>
      <ConfirmModal v-if="localeForDeletion" item-type="Locale" :item-name="localeForDeletion.name" :confirm="confirmDeleteLocale" :cancel="cancelDeleteLocale" />
      <FilteredItemList title="Locales" :fields="fields" :items="allLocales" :on-click="goto" :search="true" @delete="askToDeleteLocale" :actions="{'Add Locale': addNewLocale }" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import Container from "@/components/ui/layout/Container";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Icons from "@/lib/Icons";

export default {
  name: "Locales",
  components: {FilteredItemList, Container, ConfirmModal },

  data() {
    return {
      localeForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px"},
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Description", above: 1, prop: "description", sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
        ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allLocales.length, this.getLocales]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allLocales']),
  },

  methods: {
    ...mapActions(['getLocales','setLoading','deleteLocale']),
    goto(locale) {
      this.$router.push('/locale/'+locale.id)
    },
    addNewLocale() {
      this.$router.push('/locale/new')
    },
    askToDeleteLocale(locale) {
      this.localeForDeletion = locale;
    },
    cancelDeleteLocale() { this.localeForDeletion = null },
    confirmDeleteLocale() {
      this.deleteLocale(this.localeForDeletion.id)
      this.localeForDeletion = null
    }
  },

}
</script>
