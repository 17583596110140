<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields"
                :on-update="onUpdate" :on-submit="onSubmit"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale" />
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Api from "@/lib/Api";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";

export default {

  name: "MealStyle",
  mixins: [InstanceView],
  components: {InstanceDash },

  data() {
    return {
      type: "Meal Style",
      path: "meal-style",
      fields : [
        Fe.Text("name", "Name"),
        Fe.Textarea("description", "Description"),
      ],
      Create : this.createMealStyle,
      Update : this.updateMealStyle,
      defaultData: { name: "New Meal Style", description : ""},
      locale : { get: Api.GetMealStyleLocales, create : Api.CreateMealStyleLocale, update: Api.UpdateMealStyleLocale },
      localeFields: [ {prop: "name", type:"text", label:"Name"}, {prop: "description", type:"textarea", label:"Description"} ],
    }
  },

  async mounted() {
    await this.initInstance('allMealStyles',[!this.allMealStyles.length, this.getMealStyles])
  },

  computed: mapGetters(['allMealStyles']),
  methods: mapActions(['getMealStyles','updateMealStyle','createMealStyle']),

}
</script>
