<template>
  <InlineForm :item="newIngredient" :fields="addIngredientFields" :meta="{ingredients: allIngredients, servingSizes: servingSizesAndGrams}"
              @update="onNewIngredientUpdate" :submit-label="data?'Edit':'Add'" :validate="newIngredientIsValid" :on-submit="onSubmit" />
</template>

<script>
import InlineForm from "@/components/ui/form/InlineForm";
import Fe from "@/lib/ui/FormElement";
import {actionIf} from "@/lib/Async";
import {mapActions, mapGetters} from "vuex";

const defaultIngredient = {ingredient_id: null, serving_size_id: null, serving_amount: 1, amount_g : 0, is_dynamic: false, gap: null, max: null}

export default {
  name: "AddIngredientForm",
  props : {
    recipe: Boolean,
    data: Object
  },
  components: {InlineForm},
  data() {
    return {
      newIngredient : {...defaultIngredient},
      addIngredientFields : [
        Fe.Select('ingredient_id','Ingredient',null,'Select Ingredient','name','ingredients'),
        { ...Fe.Select('serving_size_id','Serving Size', null, 'Select Serving Size','name','servingSizes'), if : (item) => !!item.ingredient_id, filterOptions: (options, item) => {
          const ingredient = this.allIngredients.find(it => it.id === item.ingredient_id);
              let sizes = ingredient.serving_sizes.map(it => it.serving_size_id)
          return options.filter(it => sizes.indexOf(it.id) > -1 || it.id === 0)
          } },
        { ...Fe.Number('serving_amount','Serving Amount', null, 'Serving Amount'), if : (item) => !!item.ingredient_id && item.serving_size_id !== null && item.serving_size_id !== 0 },
        { ...Fe.Number('amount_g','Serving Amount', null, 'Amount (g)'), if : (item) => !!item.ingredient_id && item.serving_size_id === 0 },
        { ...Fe.Checkbox('is_fixed','Fixed' ), if : (item) => !!item.ingredient_id && item.serving_size_id !== null },
        { ...Fe.Checkbox('is_locked','Locked' ), if : (item) => !!item.ingredient_id && item.serving_size_id !== null },
        { ...Fe.Checkbox('is_dynamic','Dynamic' ), if : (item) => !!item.ingredient_id && item.serving_size_id !== null },
        { ...Fe.Number('max','Max', null, 'Max amount in G'), if : (item) => !!item.ingredient_id && item.serving_size_id === 0 && item.is_dynamic },
        { ...Fe.Number('max','Max', null, 'Max serving amount'), if : (item) => !!item.ingredient_id && item.serving_size_id > 0 && item.is_dynamic },
        { ...Fe.Number('max','Max', null, 'Max amount in G'), if : (item) => !item.is_fixed && !item.is_dynamic && item.serving_size_id === 0 },
        { ...Fe.Number('max','Max', null, 'Max serving amount'), if : (item) => !item.is_fixed && !item.is_dynamic && item.serving_size_id > 0 },
        { ...Fe.Text('gap','Gap', null, 'Gaps in Grams'), if : (item) => !!item.ingredient_id && item.serving_size_id !== null && item.is_dynamic && item.serving_size_id === 0 },

      ],
    }
  },
  async mounted() {
    await actionIf([
      [!this.allIngredients.length, this.getIngredients],
      [!this.allServingSizes.length, this.getServingSizes],
    ])()

    if (this.data) {
      this.newIngredient = {...this.data}
    }
  },
  computed : {
    ...mapGetters(['allIngredients', 'allServingSizes']),
    servingSizesAndGrams() {
      return [
        {id: 0, name: "Grams"},
        ...this.allServingSizes
      ]
    },
  },
  methods : {
    ...mapActions(['setLoading','getIngredients', 'getServingSizes']),

    onNewIngredientUpdate(values) {
      if (!values.amount_g && values.serving_size_id) {
        const size = this.allServingSizes.find(it => it.id === values.serving_size_id)
        values.amount_g = size.amount_g
      }
      this.newIngredient = values;

    },

    newIngredientIsValid() {
      return this.newIngredient && this.newIngredient.ingredient_id && !isNaN(this.newIngredient.serving_size_id) &&
          ((this.newIngredient.serving_size_id > 0 && this.newIngredient.serving_amount > 0) || this.newIngredient.amount_g > 0)
    },

    onSubmit() {
      const ingredient = this.allIngredients.find(it => it.id === this.newIngredient.ingredient_id);
      this.$emit('submit', {...this.newIngredient, ingredient})
      this.newIngredient = {...defaultIngredient}
    }
  },

  watch : {
    data() {
      if (this.data) this.newIngredient = {...this.data, ingredient_id: this.data.ingredient.id}
      else this.newIngredient = {...defaultIngredient}
    }
  }

}
</script>
