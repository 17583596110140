<template>
  <div :class="'nutrition-counter'+(compact?' compact':'')">
    <Header :small="true">{{ label ? label : 'Nutrients' }}</Header>
    <div v-if="variations.length > 1">
      <ButtonGroup :even="true">
        <Button @click="showVariations = !showVariations"> {{showVariations ? 'Show nutrition': 'Show ' + variations.length + ' variations'}}</Button>
        <Button @click="variationsCsv(ingredients)">Variations CSV</Button>
      </ButtonGroup>
      <BoxGroup v-if="showVariations">
        <Box v-for="(variation, i) in variations" :key="i">
          <Flexy v-for="(ing,ii) in variation" :key="ii">
            <Flexy v-if="ing.amount_g">
            <Txt>{{variationAmount(ing)}}</Txt>
            <Txt :bold="true">{{ing.ingredient.name}}</Txt>
            <Txt v-if="ing.serving_size">({{variationGAmount(ing)}})</Txt>
            </Flexy>
          </Flexy>
          <BoxGroup :small="true" :col="false" :even="true">
            <Box>
              <Txt>Cal: </Txt>
              <Txt :bold="true">{{variationsNutrition(ingredients)[i].calories.toFixed(2)}}</Txt>
            </Box>
            <Box><Txt>Protein: </Txt>
              <Txt :bold="true">{{variationsNutrition(ingredients)[i].protein_g.toFixed(2)}}</Txt>g
            </Box>
            <Box><Txt>Fat: </Txt>
              <Txt :bold="true">{{variationsNutrition(ingredients)[i].fat_g.toFixed(2)}}</Txt>g
            </Box>
          </BoxGroup>
        </Box>
      </BoxGroup>
    </div>
    <Box v-if="!showVariations">
      <Txt :bold="true">Nutrition</Txt>
      <Content v-for="(nut,name) in nutrients" :key="name" :between="true" :base-padding="true">
        <Txt>{{name}}</Txt>
        <Txt :bold="true">{{combined(name)}}</Txt>
      </Content>
      <div v-if="!compact">
        <h3>Summary</h3>
        <Content :between="true" :base-padding="true">
          <Txt>Protein</Txt>
          <Txt :bold="true">{{(nutrients.protein_g*4 / nutrients.calories * 100).toFixed(2)}}%</Txt>
        </Content>
        <Content :between="true" :base-padding="true">
          <Txt>Fat</Txt>
          <Txt :bold="true">{{(nutrients.fat_g*9 / nutrients.calories * 100).toFixed(2)}}%</Txt>
        </Content>
      </div>
    </Box>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Header from "@/components/ui/utils/Header";
import Txt from "@/components/ui/utils/Txt";
import Content from "@/components/ui/layout/Content";
import {mealNutrition, recipeVariations} from "@/lib/NutritionUtils";
import Flexy from "@/components/ui/layout/Flexy";
import Box from "@/components/ui/layout/Box";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Button from "@/components/ui/form/Button";
import ButtonGroup from "@/components/ui/layout/ButtonGroup";
import {downloadCsv} from "@/lib/Csv";

export default {
name: "NutritionCounter",
  components: {ButtonGroup, Button, BoxGroup, Box, Flexy, Content, Txt, Header},
  props: ['ingredients','compact','label','name'],

  data() {
    return {
      'fields': ['calories','protein_g','fat_g','carbs_g','sugars_g','fiber_g','saturated_fat_g','trans_fat_g','cholesterol_mg'],
      variations : [],
      showVariations : false
    }
  },

  mounted() {
    if (this.ingredients && this.allIngredients) this.resetVariations()
  },

  computed : {
    ...mapGetters(['allIngredients']),
    nutrients() {
      const n = {};

      for (let ing of this.ingredients) {
        if (!ing.ingredient || !ing.ingredient.id) {
          if (ing.ingredient_id && this.allIngredients) ing.ingredient = this.allIngredients.find(it => it.id === ing.ingredient_id)
          if (!ing.ingredient) continue;
        }

        for (let nut in ing.ingredient.nutrition) {
          if (this.fields.indexOf(nut) < 0) continue;
          if (isNaN(ing.ingredient.nutrition[nut]*1)) continue;
          if (!n[nut]) n[nut] = 0;
          n[nut] += ing.ingredient.nutrition[nut] / 100 * ing.amount_g * ing.serving_amount;
        }
      }
      return n;
    }
  },
  methods : {
    combined(prop) {
      const nuts = this.nutrients
      let res = nuts[prop].toFixed(2)
      if (this.maxNutrition) res += " - " + this.maxNutrition[prop].toFixed(2)
      return res
    },

    variationAmount(ing) {
      const fullAmount = ing.serving_amount * ing.amount_g,
          servingAmount = ing.max && ing.serving_size_id ? Math.min(ing.serving_amount, ing.max) : ing.serving_amount,
          amount = ing.max ? Math.min(fullAmount, ing.serving_size_id ? ing.max * ing.amount_g : ing.max) : fullAmount

      return ing.serving_size_id ? 'x'+servingAmount : amount.toFixed(2)*1 + (ing.unit_type || 'g')

    },

    variationGAmount(ing) {
      const fullAmount = ing.serving_amount * ing.amount_g,
        amount = ing.max ? Math.min(fullAmount, ing.serving_size_id ? ing.max * ing.amount_g : ing.max) : fullAmount
      return amount.toFixed(2)*1 + (ing.unit_type || 'g') +(fullAmount>amount?' (max)':'')
    },

    variationsNutrition(recipe) {
      return recipeVariations(recipe).map(it => mealNutrition(it, true, this.allIngredients, true))
    },

    variationsCsv(recipe) {
      let nut = this.variationsNutrition(recipe);
      let data = recipeVariations(recipe).map ((it,i) =>
      {
        return {
          num: i+1,
          ingredients: it.map(ing => ing.serving_size ?
              'x'+ing.serving_amount + ' ['+ing.serving_size.name + '] '+ing.ingredient.name+' ' :
              ing.amount_g + (ing.unit_type || 'g')+' '+ing.ingredient.name
          ).join(", "),
          calories : nut[i].calories.toFixed(2)*1, protein_g: nut[i].protein_g.toFixed(2)*1, fat_g: nut[i].fat_g.toFixed(2)*1
        }
      });

      downloadCsv(data,[
          {prop: 'num', label: "#"},
          {prop: 'ingredients',label:'Ingredients'},
          {prop: 'calories',label:'Calories'},
          {prop: 'protein_g',label:'Protein (g)'},
          {prop: 'fat_g',label:'Fat (g)'},
      ], (this.name||'recipe')+' - Variations.csv')

    },

    resetVariations() {
      this.variations = recipeVariations(this.ingredients)
      this.maxNutrition = this.variations.length > 1 ? mealNutrition(this.variations[this.variations.length-1], true, this.allIngredients, true) : null
    },
  },

  watch : {
    ingredients() { this.resetVariations() }
  }
}
</script>
