<template>
  <div class="filtered-list">
    <div class="filters-bar">
      <div>
        <h2 v-if="title">{{title}}</h2>
      </div>
      <div class="filters">
        <div class="actions" v-if="actions && Object.keys(actions).length">
            <Button v-for="(action,name) in actions" :key="name" @click="action">{{name}}</Button>
        </div>
        <v-select v-for="(filter, prop) in filters" :key="prop" v-model="filterData[prop]" :options="filterOptions(prop)" label="name" :placeholder="'Filter By '+filter" />
        <div class="search" v-if="search">
          <Input placeholder="Search..." :value="searchQuery" @input="onSearch" />
        </div>
      </div>
    </div>
    <ItemList :fields="fields" :items="filteredItems" :on-click="onClick" v-on="customEvents" :preformatted="true"
              :max-items="maxItems" :page="page" :on-page="onPage" />
  </div>
</template>

<script>
import ItemList from "@/components/ui/lists/ItemList";
import Input from "@/components/ui/form/Input";
import {getListValues} from "@/lib/ui/UiUtils";
import Button from "@/components/ui/form/Button";
export default {
name: "FilteredItemList",
  components: {Button, Input, ItemList},
  props : {
    search: { type: [Boolean, Array] },
    filters: { type: [Array, Object] },
    actions: { type: Object },
    items: Array,
    fields: Array,
    title: String,
    noFormatting: Boolean,
    onClick: Function,
    maxItems : {type: Number, default: null},
    page: {type: Number, default: 1},
    onPage: {type: Function, default: () => console.log("hxxxe")},
  },
  data() {
    return {
      searchQuery : "",
      filterData : {}
    }
  },
  computed : {
    filteredItems() {
      let items = getListValues(this.items, this.fields)
      if (!items.length) return []
      if (this.searchQuery && this.search) items = this.applySearch(items)
      for (let d in this.filterData) {
        if (!this.filterData[d]) continue
        items = items.filter(it => it[d] === this.filterData[d])
      }
      return items;
    },
    customEvents() {
      const events = {};
      for (let f of this.fields) if (f.actions) {
        for (let a of f.actions) if (a.action) events[a.action] = (v) => this.$emit(a.action, v)
      }
      return events;
    }
  },
  methods : {
    onSearch(search) {
      this.searchQuery = search;
    },
    filterOptions(prop) {
      const items = getListValues(this.items, this.fields);
      let opts = {}
      for (let item of items) if (item[prop]) opts[item[prop]] = true;
      return Object.keys(opts)
    },
    applySearch(items) {
      if (this.search) {
        items = items.filter(item => {
          if (this.search instanceof Array) {
            for (let prop of this.search) {
              if (item[prop] && item[prop].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase())>-1) return true;
            }
          }
          else {
            for (let prop in item) {
              if (item[prop] && item[prop].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase())>-1) return true;
            }
          }
        })
      }
      return items;
    }
  }
}
</script>
