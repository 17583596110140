import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        locales : [],
    },
    getters : {
        allLocales: state => state.locales,
    },
    actions : {
        async deleteLocale({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteLocale, id))
            return await commit('deleteLocale', id)
        },
        async getLocales({commit}) {
            const locales = await ApiRequest(ApiCall(Api.ListLocales));
            return await commit('setLocales', locales);
        },
        async updateLocale({commit, dispatch}, [locale, id]) {
            const res = await ApiRequest(ApiCall(Api.UpdateLocale, locale, id))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                locale.id = res.insertId || res.id
                await dispatch('setSuccess', 'Locale Updated')
                return await commit('saveLocale', [locale, id])
            }
        },
        async createLocale({commit, dispatch}, locale) {
            const res = await ApiRequest(ApiCall(Api.CreateLocale, locale))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                locale.id = res.insertId || res.id
                await dispatch('setSuccess', 'Locale Created')
                return await commit('saveLocale', [locale, null])
            }
        },
    },
    mutations : {
        setLocales: (state, locales) => state.locales = locales,
        saveLocale: (state, [locale, id]) => {
            if (id) {
                state.locales = state.locales.map(it => it.id === locale.id ? locale : it)
            }
            else {
                state.locales = [...state.locales, locale];
            }
            return state
        },
        deleteLocale(state, id) {
            state.locales = state.locales.filter(it => it.id !== id)
        },
    }
}
