<template>
  <Container v-if="instance">
    <Panel>
      <Header>{{type}}: <b>{{instanceTitle}}</b></Header>
      <Content :full="1">
        <Content :flex="{2:3, 3:2, 4:2, 5:3}" :col="true">
          <Scroller :full="true">
            <AppForm :fields="fields" :data="instance" @update="onUpdate" :meta="meta" />
            <slot name="main"></slot>
          </Scroller>
        </Content>
        <Content :flex="{2:2,3:1,4:1,5:1}">
          <Scroller :full="true">
            <BoxGroup :col="true">
              <Box v-if="locales" title="Locales">
                <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" secondary="name_plural" :on-submit="setLocale" />
              </Box>
              <slot name="sidebar"></slot>
              <Box>
                <Button @click="onSubmit">{{ instance.id?'Update '+type:'Create '+type }}</Button>
              </Box>
            </BoxGroup>
          </Scroller>
        </Content>
      </Content>
    </Panel>
  </Container>
</template>


<script>
import Container from "@/components/ui/layout/Container";
import Panel from "@/components/ui/layout/Panel";
import Content from "@/components/ui/layout/Content";
import Scroller from "@/components/ui/layout/Scroller";
import AppForm from "@/components/ui/form/AppForm";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import LocaleForm from "@/components/forms/LocaleForm";
import Button from "@/components/ui/form/Button";
import Header from "@/components/ui/utils/Header";

export default {
  name: "InstanceDash",
  props: {
    onUpdate: Function,
    onSubmit : Function,
    instance: Object,
    titleProp: {type:String, default: "name"},
    meta: {type: Object, default: () => {}},
    type: String,
    fields: Array,
    locales: {type:Array, default: null},
    allLocales: {type:Array, default: null},
    localeFields: {type:Array, default: null},
    setLocale: {type: Function, default: () => {}},
  },
  components: {Header, Button, LocaleForm, Box, BoxGroup, AppForm, Scroller, Content, Panel, Container},

  computed : {
    instanceTitle() {
      return this.instance && this.instance[this.titleProp]
    }
  }

}
</script>
