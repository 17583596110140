<template>
  <span :class="className">
    <a v-if="link" :href="link" :class="mood"><slot></slot></a>
    <router-link v-if="routerLink" :to="routerLink"><slot></slot></router-link>
    <slot v-if="!link && !routerLink" @click="onClick"></slot>
  </span>
</template>

<script>
export default {
  name: "Txt",
  props: ['mood', 'bold', 'link', 'routerLink','action'],
  computed: {
    className() {
      let cls = 'txt';
      if (this.bold) cls += ' bold'
      switch (this.mood) {
        case 'primary': cls += ' primary'; break;
        case 'success': cls += ' success'; break;
        case 'warning': cls += ' warning'; break;
        case 'danger': cls += ' danger'; break;
      }
      return cls
    }
  },
  methods : {
    onClick() {
      if (this.action) this.action()
    }
  }
}
</script>
