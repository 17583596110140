import Fe from '../ui/FormElement';
export default [
    Fe.Text("name","Name","main"),
    Fe.Text("slug","Slug","main"),
    Fe.Text("sku","SKU","main"),
    Fe.Select("unit_type","Unit Type","main", "Unit Type","short", "Units", "short", false),

    Fe.Number("nutrition.calories","Calories","primary"),
    Fe.Number("nutrition.protein_g","Protein (G)","primary"),
    Fe.Number("nutrition.fat_g","Fat (G)","primary"),
    Fe.Number("nutrition.carbs_g","Carbs (G)","primary"),

    Fe.Number("nutrition.sugars_g", "Sugars (G)", "secondary"),
    Fe.Number("nutrition.fiber_g", "Fiber (G)", "secondary"),
    Fe.Number("nutrition.saturated_fat_g", "Saturated Fat (G)", "secondary"),
    Fe.Number("nutrition.trans_fat_g", "Trans Fat (G)", "secondary"),
    Fe.Number("nutrition.cholesterol_mg", "Cholesterol (MG)", "secondary"),
    Fe.Number("nutrition.water_g", "Water (G)", "secondary"),
    Fe.Number("nutrition.alcohol_ethyl_g", "Alcohol Ethyl (G)", "secondary"),

    Fe.Number("nutrition.sodium_mg", "Sodium (MG)", "small",'','Minerals'),
    Fe.Number("nutrition.calcium_mg", "Calcium (MG)", "small"),
    Fe.Number("nutrition.magnesium_mg", "Magnesium (MG)", "small"),
    Fe.Number("nutrition.phosphorus_mg", "Phosphorus (MG)", "small"),
    Fe.Number("nutrition.potassium_mg", "Potassium (MG)", "small"),
    Fe.Number("nutrition.zinc_mg", "Zinc (MG)", "small"),
    Fe.Number("nutrition.iron_mg", "Iron (MG)", "small"),
    Fe.Number("nutrition.copper_mg", "Copper (MG)", "small"),

    Fe.Number("nutrition.carotene_beta_ug", "Carotene, Beta (UG)", "small 2",'','Pigments and Alkaloids'),
    Fe.Number("nutrition.carotene_alpha_ug", "Carotene, Alpha (UG)", "small 2"),
    Fe.Number("nutrition.cryptoxanthin_beta_ug", "Cryptoxanthin, Beta (UG)", "small 2"),
    Fe.Number("nutrition.lycopene_ug", "Lycopene (UG)", "small 2"),
    Fe.Number("nutrition.caffeine_mg", "Caffeine (MG)", "small 2"),
    Fe.Number("nutrition.theobromine_mg", "Theobromine (MG)", "small 2"),

    Fe.Number("nutrition.vitamin_a_rae_ug", "Vitamin A, RAE (UG)", "small 3",'','Vitamins'),
    Fe.Number("nutrition.vitamin_b_6_mg", "Vitamin B-6 (MG)", "small 3"),
    Fe.Number("nutrition.vitamin_b_12_ug", "Vitamin B-12 (UG)", "small 3"),
    Fe.Number("nutrition.vitamin_b_12_added_ug", "Vitamin B-12, added (UG)", "small 3"),
    Fe.Number("nutrition.vitamin_d_d2_d3_ug", "Vitamin D (D2 + D3) (UG)", "small 3"),
    Fe.Number("nutrition.vitamin_e_alpha_tocopherol_mg", "Vitamin E (alpha-tocopherol) (MG)", "small 3"),
    Fe.Number("nutrition.vitamin_e_added_mg", "Vitamin E, added (MG)", "small 3"),
    Fe.Number("nutrition.vitamin_k_phylloquinone_ug", "Vitamin K (phylloquinone) (UG)", "small 3"),
    Fe.Number("nutrition.niacin_mg", "Niacin (MG)", "small 3"),
    Fe.Number("nutrition.riboflavin_mg", "Riboflavin (MG)", "small 3"),
    Fe.Number("nutrition.thiamin_mg", "Thiamin (MG)", "small 3"),
    Fe.Number("nutrition.folate_total_ug", "Folate, total (UG)", "small 3"),
    Fe.Number("nutrition.choline_total_mg", "Choline, total (MG)", "small 3"),
    Fe.Number("nutrition.folic_acid_ug", "Folic acid (UG)", "small 3"),
    Fe.Number("nutrition.folate_food_ug", "Folate, Food (UG)", "small 3"),
    Fe.Number("nutrition.folate_dfe_ug", "Folate, DFE (UG)", "small 3"),

    Fe.Number("nutrition.sfa_40_g", "SFA 4:0", "small 4",'',"Amino/Fatty Acids"),
    Fe.Number("nutrition.sfa_60_g", "SFA 6:0", "small 4"),
    Fe.Number("nutrition.sfa_80_g", "SFA 8:0", "small 4"),
    Fe.Number("nutrition.sfa_100_g", "SFA 10:0", "small 4"),
    Fe.Number("nutrition.sfa_120_g", "SFA 12:0", "small 4"),
    Fe.Number("nutrition.sfa_140_g", "SFA 14:0", "small 4"),
    Fe.Number("nutrition.sfa_160_g", "SFA 16:0", "small 4"),
    Fe.Number("nutrition.sfa_180_g", "SFA 18:0", "small 4"),
    Fe.Number("nutrition.mufa_161_g", "MUFA 16:1", "small 4"),
    Fe.Number("nutrition.mufa_181_g", "MUFA 18:1", "small 4"),
    Fe.Number("nutrition.mufa_221_g", "MUFA 22:1", "small 4"),
    Fe.Number("nutrition.pufa_182_g", "PUFA 18:2", "small 4"),
    Fe.Number("nutrition.pufa_183_g", "PUFA 18:3", "small 4"),
    Fe.Number("nutrition.pufa_204_g", "PUFA 20:4", "small 4"),
    Fe.Number("nutrition.pufa_25_n_3_epa_g", "PUFA 2:5 n-3 (EPA)", "small 4"),
    Fe.Number("nutrition.pufa_225_n_3_dpa_g", "PUFA 22:5 n-3 (DPA)", "small 4"),
    Fe.Number("nutrition.pufa_226_n_3_dha_g", "PUFA 22:6 n-3 (DHA)", "small 4"),
    Fe.Number("nutrition.fatty_acids_total_monounsaturated_g", "Fatty acids, total monounsaturated", "small 4"),
    Fe.Number("nutrition.fatty_acids_total_polyunsaturated_g", "Fatty acids, total polyunsaturated", "small 4"),

    Fe.Checkbox("traits.is_gluten_free", "Gluten Free", "small 5","Traits"),
    Fe.Checkbox("traits.is_vegan", "Vegan", "small 5"),
    Fe.Checkbox("traits.is_paleo", "Paleo", "small 5"),
    Fe.Checkbox("traits.is_dairy_free", "Dairy Free", "small 5"),
    Fe.Checkbox("traits.is_ketogenic", "Ketogenic", "small 5"),
    Fe.Checkbox("traits.is_kosher", "Kosher", "small 5"),
    Fe.Checkbox("traits.is_vegetarian", "Vegetarian", "small 5"),
    Fe.Checkbox("traits.is_mediterranean", "Mediterranean", "small 5"),

]
