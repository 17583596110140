<template>
  <Panel>
    <h3>Add Market</h3>
    <Content>
      <v-select v-model="newMarket" :options="markets" :reduce="it => it.id" label="name" placeholder="- Choose Market -" />
      <input v-model="newAmountG" type="number" class="unwrap" placeholder="Amount (G)" />
      <Button class="square" @click="addMarket">+</Button>
    </Content>
  </Panel>
</template>

<script>
import vSelect from 'vue-select';
import Panel from "@/components/ui/layout/Panel";
import Content from "@/components/ui/layout/Content";
import Button from "@/components/ui/form/Button";

export default {
  name: "AddMarketForm",
  props: ['markets'],
  components: {Button, Content, Panel, vSelect},

  data() {
    return {
      newMarket : '',
      newAmountG: '',
    }
  },
  methods : {
    addMarket() {
      if (this.newMarket) {
        this.$emit('add', this.newMarket, this.newAmountG)
        this.newMarket = this.newAmountG = ''
      }
    }
  }
}
</script>
