<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :on-update="onUpdate" :on-submit="onSubmit">
    <template slot="main">
      <Header :small="true">Admin permissions</Header>
      <Content>
        <div class="permission" v-for="(permission) in permissionGroups.admin" :key="permission">
          <input type="checkbox" :checked="hasPermission(permission)" @change="togglePermission(permission)">
          <p>{{permission}}</p>
        </div>
      </Content>
      <Header :small="true">Editor permissions</Header>
      <Content>
        <div class="permission" v-for="(permission) in permissionGroups.editor" :key="permission">
          <input type="checkbox" :checked="hasPermission(permission)" @change="togglePermission(permission)">
          <p>{{permission}}</p>
        </div>
      </Content>
      <Header :small="true">User permissions</Header>
      <Content>
        <div class="permission" v-for="(permission) in permissionGroups.user" :key="permission">
          <input type="checkbox" :checked="hasPermission(permission)" @change="togglePermission(permission)">
          <p>{{permission}}</p>
        </div>
      </Content>
    </template>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Header from "@/components/ui/utils/Header";
import Content from "@/components/ui/layout/Content";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";

export default {

  name: "UserRole",
  mixins: [InstanceView],
  components: {InstanceDash, Content, Header },

  data() {
    return {
      type: "User Role",
      path: "user-role",
      fields : [ Fe.Text("name", "Name") ],
      Create : this.createUserRole,
      Update : this.updateUserRole,
      defaultData: { name: "New User Role", slug: "" },
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'allUserRoles', 'allUserPermissions']),

    permissionGroups() {
      const groups = {'admin': [], 'editor': [], 'user': []};
      for (let permission of this.allUserPermissions) {
        if (permission.toLowerCase().indexOf('admin') === 0) groups.admin.push(permission);
        else if (permission.toLowerCase().indexOf('edit') === 0) groups.editor.push(permission);
        else groups.user.push(permission);
      }
      return groups;
    }
  },

  async mounted() {
    await this.initInstance('allUserRoles',[
        [!this.allUserRoles.length, this.getUserRoles],
      [!this.allUserPermissions.length, this.getUserPermissions]
    ])
  },

  methods: {
    ...mapActions(['getUserRoles','getUserPermissions','updateUserRole','createUserRole']),
    hasPermission(permission) {
      return this.instance && !!this.instance.permissions && this.instance.permissions.find(it => it.permission === permission)
    },
    togglePermission(permission) {
      if (this.instance.permissions.find(it => it.permission === permission)) {
        this.instance.permissions = this.instance.permissions.filter(it => it.permission !== permission);
      }
      else {
        this.instance.permissions.push({role_id: this.instance.id, permission})
      }
    },
  },



}
</script>
