<template>
  <div :class="className" :style="styles">
    <slot></slot>
  </div>
</template>

<script>
import Flexer from "@/mixins/Flexer";
import {Breakpoints} from "@/lib/ui/UiUtils";

export default {
  name: "Part",
  mixins: [Flexer],
  props : {
    width : {type: [Number, Object], default: null },
  },
  data() {
    return {
      clsName: 'part',
      elWidth: null,
      classIfs: [
          [this.width, 'max_width']
      ],
      inheritsFlexyViaSass: true,
    }
  },
  mounted() {
    this.calculateWidth();
    window.addEventListener('resize', this.calculateWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateWidth)
  },

  computed : {
    styles() {
      let r = {}
      if (this.elWidth) {
        r.width = this.elWidth+'px'
      }
      return r
    }
  },
  methods : {
    getBreakpointWidth(defs) {
      let width = null;
      for (let bp in defs) {
        switch (bp*1) {
          case 1: if (window.innerWidth <= Breakpoints.mobile) width = this.width[bp]; break;
          case 2: if (window.innerWidth <= Breakpoints.tablet && window.innerWidth > Breakpoints.mobile) width = defs[bp]; break;
          case 3: if (window.innerWidth <= Breakpoints.wide && window.innerWidth > Breakpoints.tablet) width = defs[bp]; break;
          case 4: if (window.innerWidth <= Breakpoints.wider && window.innerWidth > Breakpoints.wide) width = defs[bp]; break;
          case 5: if (window.innerWidth <= Breakpoints.widest && window.innerWidth > Breakpoints.wider) width = defs[bp]; break;
        }
      }
      return width;
    },
    calculateWidth() {
      this.elWidth = null;
      if (this.width) {
        if (!isNaN(this.width)) this.elWidth = this.width
        else this.elWidth = this.getBreakpointWidth(this.width)
      }
    }
  }

}
</script>
