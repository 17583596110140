<template>
  <div :class="className">
    <slot></slot>
  </div>
</template>

<script>
import Flexer from "@/mixins/Flexer";

export default {
  name: "ButtonGroup",
  mixins: [Flexer],
  props: {
    noBorder: Boolean,
    noExternalBorder: Boolean,
    col: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
      clsName: 'button-group',
      inheritsFlexyViaSass: true,
      classIfs: [
        [this.noBorder, 'no-border'],
        [this.noExternalBorder, 'no-external-border'],
      ]
    }
  },
}
</script>
