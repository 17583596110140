import {getToken} from './Storage';
import ApiRoutes from "@/lib/ApiRoutes";

export default ApiRoutes;

export function ApiCall(endpoint, body = null, query = null, params = null) {

    // replace body for query and then to params to allow using 1st param for queries or params
    if (!endpoint.body && (endpoint.query || endpoint.params) && !query) { query = body; body = null; }
    if (!endpoint.query && endpoint.params && !params) { params = query; }

    let req = {
        body : {},
        method: endpoint.method,
        params: endpoint.params,
        url : '',
    };

    if (endpoint.body) {
        req.body = body;
    }

    let url = new URL(process.env.VUE_APP_API_URL + endpoint.path), prop;
    let endpointParams = endpoint.path.match(/\/:([A-Za-z0-9._-]+)/g);
    if (endpointParams && endpointParams.length > 0) {
        url = new URL(process.env.VUE_APP_API_URL + endpoint.path.match(/\/([A-Za-z0-9._-]+)/g).join(''))
        if (typeof params === "number" || typeof params === "string") {
            params = {[endpointParams[0].slice(2)] : params }
        }
        for (let p of endpointParams) {
            prop = p.slice(2);
            if (params[prop]) url += (url[url.length-1]==='/'?'':'/') + params[prop];
            else {
                console.error("Missing URL param!", prop)
            }
        }
    }

    if (endpoint.query) {
        for (let i of endpoint.query) if (typeof query[i] !== "undefined") url.searchParams.append(i, query[i]);
    }

    if (endpoint.upload) {
        req.upload = endpoint.upload;
        if (!req.headers) req.headers = {}
        req.headers['Content-Type'] = "multipart/form-data";
    }

    if (endpoint.token) {
        const token = getToken();
        if (token) {
            if (!req.headers) req.headers = {};
            req.headers.token = token;
        }
    }

    req.url = url.toString();

    return req;
}

export const doRequest = async (uri, method = "GET", body = {}, additionalHeaders = {}, upload = '') => {

    let headers = new Headers();

    const params = {
        method, headers,
        credentials: 'include'
    };

    if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {
        if (!additionalHeaders['Content-Type']) {
            headers.append('Content-Type','application/json');
        }
        params.body = JSON.stringify(body);
    }

    if (additionalHeaders) {
        for (let h in additionalHeaders)
            headers.append(h, additionalHeaders[h])
    }

    if (upload) {
        const data = new FormData();
        data.append(upload, body[upload])
        headers.delete('Content-Type')
        params.body = data;
    }

    try {
        const response = await fetch(uri, params);
        if (response.status === 404 || response.status === 400) {
            const resJson = await response.json();
            throw({fetchError : resJson});
        }
        else if (response.status === 200) {
            return await response.json();
        }
    } catch (fetchError) {
        throw({fetchError});
    }
};

export async function ApiRequest(call) {
    return await doRequest(call.url, call.method, call.body, call.headers, call.upload);
}
