<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields"
                :on-update="onUpdate" :on-submit="onSubmit"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale" />
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Api from "@/lib/Api";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";

export default {

  name: "Market",
  mixins: [InstanceView],
  components: {InstanceDash,  },
  data() {
    return {
      type: "Market",
      path: "market",
      fields : [ Fe.Text("name", "Name") ],
      Create : this.createMarket,
      Update : this.updateMarket,
      defaultData: {
        name: "New Market",
        amount_g: 0,
        markets: []
      },
      locale : { get: Api.GetMarketLocales, create : Api.CreateMarketLocale, update: Api.UpdateMarketLocale },
      localeFields: [ {prop: "name", type:"text", label:"Name"} ],
    }
  },

  async mounted() {
    await this.initInstance('allMarkets',[!this.allMarkets.length, this.getMarkets()])
  },

  computed: {
    ...mapGetters(['allMarkets']),
  },

  methods: {
    ...mapActions(['getMarkets', 'updateMarket', 'createMarket']),
  }
}
</script>
