<template>
    <Container>
      <ConfirmModal v-if="snapshotForDeletion" item-type="Snapshot" :item-name="snapshotForDeletion.name" :confirm="confirmDeleteSnapshot" :cancel="cancelDeleteSnapshot" />
      <FilteredItemList title="Snapshots" :fields="fields" :items="allSnapshots" :on-click="goto" :search="true" @delete="askToDeleteSnapshot" :actions="{'Add Snapshot': addNewSnapshot }" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import Container from "@/components/ui/layout/Container";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Icons from "@/lib/Icons";

export default {
  name: "Snapshots",
  components: {FilteredItemList, Container, ConfirmModal },

  data() {
    return {
      snapshotForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px"},
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Size", prop: "size", sortable: true, format: (b) => this.snapSize(b)},
        {label: "Description", above: 1, prop: "description", sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
        ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allSnapshots.length, this.getSnapshots]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allSnapshots']),

  },

  methods: {
    ...mapActions(['getSnapshots','setLoading','deleteSnapshot']),
    snapSize(b) {
      if (b > 1024*1024) {
        return parseFloat(b / 1024 / 1024).toFixed(2)+' mb'
      }
      else if (b > 1024) {
        return parseFloat(b / 1024).toFixed(2)+' kb'
      }
      return b;
    },
    goto(snapshot) {
      this.$router.push('/snapshot/'+snapshot.id)
    },
    addNewSnapshot() {
      this.$router.push('/snapshot/new')
    },
    askToDeleteSnapshot(snapshot) {
      this.snapshotForDeletion = snapshot;
    },
    cancelDeleteSnapshot() { this.snapshotForDeletion = null },
    confirmDeleteSnapshot() {
      this.deleteSnapshot(this.snapshotForDeletion.id)
      this.snapshotForDeletion = null
    }
  },

}
</script>
