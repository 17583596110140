<template>
    <Container>
      <ConfirmModal v-if="recipeForDeletion" item-type="Recipe" :item-name="recipeForDeletion.name" :confirm="confirmDeleteRecipe" :cancel="cancelDeleteRecipe" />
      <FilteredItemList title="Recipes" :fields="fields" :items="allRecipes" :on-click="goto" :search="true" @delete="askToDeleteRecipe" :actions="{'Add Recipe': addNewRecipe }" export-file="recipes.csv" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {mealNutrition, recipeVariations} from "@/lib/NutritionUtils";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import Container from "@/components/ui/layout/Container";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Icons from "@/lib/Icons";

export default {
  name: "Recipes",
  components: {FilteredItemList, Container, ConfirmModal },
  data() {
    return {
      recipeForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: 'str', width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "", prop: "id", target:"errors", primary: true, sortable: true, pill: 'error', format: (id) => this.recipeErrors(id) },
        {label: "Servings", prop: "servings", above: 3, width: "10%", sortable: true},
        {label: "Special", prop: "is_special", above: 3, width: "10%", type: 'boolean', sortable: true},
        {label: "Calories", prop: "id", target:"calories_g", above: 1, width:"70px", sortable: true, format : (recipe_id) => this.rowNutrition(recipe_id, 'calories')},
        {label: "Protein (g)", prop: "id", target:"protein_g", above: 2, width:"100px", sortable: true, format : (recipe_id) => this.rowNutrition(recipe_id, 'protein_g')},
        {label: "Fat (g)", prop: "id", target:"fat", width:"70px", above: 2, sortable: true, format : (recipe_id) => this.rowNutrition(recipe_id, 'fat_g')},
        {label: "% Protein", prop: "id", target:"protein_percent", above: 1, width:"80px", sortable: true, format : (recipe_id) => this.rowPercentage(recipe_id, 'protein_g', 4)},
        {label: "% Fat", prop: "id", target:"fat_percent", above: 1, width:"60px", sortable: true, format : (recipe_id) => this.rowPercentage(recipe_id, 'fat_g', 9)},
        {label: "Vars.", prop: "id", target:"variations", above: 1, width:"30px", sortable: true, format : (recipe_id) => this.rowVariations(recipe_id, 'calories')},
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allRecipes.length, this.getRecipes],
          [!this.allIngredients.length, this.getIngredients],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allRecipes','allIngredients']),
  },

  methods: {
    ...mapActions(['getRecipes','setLoading','deleteRecipe','getIngredients']),
    goto(recipe) {
      this.$router.push('/recipe/'+recipe.id)
    },

    rowVariations(recipe_id) {
      if (this.allRecipes) {
        const target = this.allRecipes.find(it => it.id === recipe_id);
        if (target) {
          const variations = recipeVariations(target.ingredients)
          if (variations.length > 1) return variations.length
        }
      }
      return ''
    },

    rowNutrition(recipe_id, prop) {
      if (this.allRecipes) {
        const target = this.allRecipes.find(it => it.id === recipe_id);
        let nutritionMax;
        if (target) {
          const variations = recipeVariations(target.ingredients)
          if (variations.length > 1) {
            nutritionMax = mealNutrition(variations[variations.length-1],false, this.allIngredients, true)
          }
          const nutrition = mealNutrition(this.allRecipes.find(it => it.id === recipe_id), false, this.allIngredients, true);
          return nutrition[prop] + (nutritionMax ? ' - '+nutritionMax[prop] : '')
        }
      }
    },
    rowPercentage(recipe_id, prop, factor) {
      let res;
      if (this.allRecipes) {
        const target = this.allRecipes.find(it => it.id === recipe_id);
        if (target) {
          const nutrition = mealNutrition(this.allRecipes.find(it => it.id === recipe_id), false, this.allIngredients, true);
          res = (nutrition[prop] * factor / nutrition.calories * 100).toFixed(2);
        }

        return res;
      }
    },

    recipeErrors(id) {
      const target = this.allRecipes.find(it => it.id === id);
      for (let ing of target.ingredients) {
        if (!ing.ingredient) return "Missing Ingredient"
      }
      return ''
    },

    addNewRecipe() {
      this.$router.push('/recipe/new')
    },
    askToDeleteRecipe(recipe) {
      this.recipeForDeletion = recipe;
    },
    cancelDeleteRecipe() { this.recipeForDeletion = null },
    confirmDeleteRecipe() {
      this.deleteRecipe(this.recipeForDeletion.id)
      this.recipeForDeletion = null
    }
  },
}
</script>
