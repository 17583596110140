<template>
  <Floater :offset-y="32" :container="container" :constrain-x="constrainX">
    <BoxGroup class="floating-menu">
      <Box v-for="(item, i) in items" :key="i" :router-link="item.to" :line="true">
        {{item.label}}
      </Box>
    </BoxGroup>
  </Floater>
</template>

<script>
import Floater from "@/components/ui/utils/Floater";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
export default {
  name: "NavFloatingMenu",
  components: {Box, BoxGroup, Floater},
  props: ['items','container','constrainX']
}
</script>
