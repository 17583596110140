<template>
    <Container>
      <ConfirmModal v-if="mealTypeForDeletion" item-type="Meal Type" :item-name="mealTypeForDeletion.name" :confirm="confirmDeleteMealType" :cancel="cancelDeleteMealType" />
      <FilteredItemList title="Meal Types" :fields="fields" :items="allMealTypes" :on-click="goto" :search="true" @delete="askToDeleteMealType" :actions="{'Add Meal Type': addNewMealType }" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "MealTypes",
  components: {Container, FilteredItemList, ConfirmModal },
  data() {
    return {
      mealTypeForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: 'str', width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allMealTypes.length, this.getMealTypes]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMealTypes']),
  },

  methods: {
    ...mapActions(['getMealTypes','setLoading','deleteMealType']),
    goto(mealType) {
      this.$router.push('/meal-type/'+mealType.id)
    },
    addNewMealType() {
      this.$router.push('/meal-type/new')
    },
    askToDeleteMealType(mealType) {
      this.mealTypeForDeletion = mealType;
    },
    cancelDeleteMealType() { this.mealTypeForDeletion = null },
    confirmDeleteMealType() {
      this.deleteMealType(this.mealTypeForDeletion.id)
      this.mealTypeForDeletion = null
    }
  },

}
</script>
