<template>
  <div class="theme-switcher" @click="switchTheme"></div>
</template>

<script>
export default {
  name: "ThemeSwitcher",
  mounted() {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') this.switchTheme()
  },
  methods : {
    switchTheme() {
      if (document.body.classList.contains('dark')) {
        document.body.classList.remove('dark')
        localStorage.setItem('theme','')
      }
      else {
        document.body.classList.add('dark')
        localStorage.setItem('theme','dark')
      }

    }
  }
}
</script>
