<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields"
                :on-update="onUpdate" :on-submit="onSubmit" :meta="{mealStyles:allMealStyles}"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale">
    <template slot="main">
      <DraggableList title="Ingredients" :items="sortedIngredients" :fields="ingredientFields" item-type="ingredient" @delete="onDeleteIngredient" @sort="onSortIngredient" />
      <AddIngredientForm @submit="onAddIngredient" />
    </template>
    <Box slot="sidebar" v-if="instance && instance.id && instance.ingredients && instance.ingredients.length">
      <NutritionCounter :ingredients="instance.ingredients" />
    </Box>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Api from "@/lib/Api";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import IngredientListView from "@/mixins/views/IngredientListView";
import AddIngredientForm from "@/components/forms/AddIngredientForm";
import DraggableList from "@/components/ui/lists/DraggableList";
import NutritionCounter from "@/components/ui/stats/NutritionCounter";
import Box from "@/components/ui/layout/Box";
import mealFields from "@/lib/form-fields/meal";

export default {
  name: "Meal",
  mixins: [InstanceView, IngredientListView],
  components: {Box,NutritionCounter,DraggableList,AddIngredientForm,InstanceDash},
  data() {
    return {
      type: "Meal",
      path: "meal",
      fields : mealFields,
      Create : this.createMeal,
      Update : this.updateMeal,
      defaultData: {
        name: "New Meal",
        description: "",
        ingredients: []
      },
      locale : { get: Api.GetMealLocales, create : Api.CreateMealLocale, update: Api.UpdateMealLocale },
      localeFields : [ {prop: "name", label:"Name", type:"text"}, {prop: "description", label:"Description", type:"textarea"} ],
      ingredientKey : "meal_id",
    }
  },

  async mounted() {
    await this.initInstance('allMeals',[[!this.allMeals.length, this.getMeals], [!this.allMealStyles.length, this.getMealStyles]])
  },

  computed: mapGetters(['allMeals', 'allMealStyles', 'allServingSizes']),
  methods: mapActions(['getMeals', 'getMealStyles', 'updateMeal','createMeal']),
}
</script>
