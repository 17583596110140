<template>
  <div class="floater" :style="floaterStyle" ref="floater"><slot></slot></div>
</template>

<script>
export default {
  name: "Floater",
  props: ['offsetX', 'offsetY','container', 'constrainX'],
  data() {
    return {
      inited: false
    }
  },
  mounted() {
    this.inited = true
  },
  computed : {
    floaterStyle() {
      let s = {}, left = 0, top = 0
      if(!this.container || !this.inited) return s;

      if (this.container && this.$refs.floater && this.constrainX) {

        if (this.constrainX === 'right') {
          left += this.container.clientWidth - this.$refs.floater.clientWidth
        }
        else if (this.constrainX === 'center') {
          left += this.container.clientWidth/2 - this.$refs.floater.clientWidth/2
        }
      }
      if (this.offsetX) left += this.offsetX
      if (this.offsetY) top += this.offsetY

      if (left) s.left = left+'px'
      if (top) s.top = top+'px'

      return s;
    }
  }
}
</script>
