export default {

    // login
    CheckLogin : { path : "/user/check-login", method: "get", token: true },
    Login : { path : '/user/login', method: "post", body: true },
    Logout : { path: '/user/logout', method: "get", token: true },

    // admin stuff

    AdminHome : { path : '/admin/', method: "get", token: true },

    // locales
    CreateLocale : { path: '/locale/add', method: "post", body: true, token: true },
    UpdateLocale : { path: '/locale/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteLocale : { path: '/locale/delete/:id', method: "get", params: true, token: true },

    // users
    ListSnapshots : { path: '/snapshots/list', method: "get", token: true },
    CreateSnapshot : { path: '/snapshots/add', method: "post", body: true, token: true },
    UpdateSnapshot : { path: '/snapshots/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteSnapshot : { path: '/snapshots/delete/:id', method: "get", params: true, token: true },

    // users
    ListUsers : { path: '/user/list', method: "get", token: true },
    CreateUser : { path: '/user/add', method: "post", body: true, token: true },
    UpdateUser : { path: '/user/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteUser : { path: '/user/delete/:id', method: "get", params: true, token: true },

    // roles
    ListUserPermissions : { path: '/user-roles/permissions', method: "get", token: true },
    ListUserRoles : { path: '/user-roles/list', method: "get", token: true },
    CreateUserRole : { path: '/user-roles/add', method: "post", body: true, token: true },
    UpdateUserRole : { path: '/user-roles/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteUserRole : { path: '/user-roles/delete/:id', method: "get", params: true, token: true },

    // locales
    ListLocales : { path: '/locale/list', method: "get", token: true },

    // meal types
    ListMealTypes : { path: '/meal-types/list', method: "get", token: true },
    CreateMealType : { path: '/meal-types/add', method: "post", body: true, token: true },
    UpdateMealType : { path: '/meal-types/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteMealType : { path: '/meal-types/delete/:id', method: "get", params: true, token: true },
    GetMealTypeLocales : { path: '/meal-types/locales/:id', method: "get", params: true, token: true },
    CreateMealTypeLocale : { path: '/meal-types/add/:id', method: "post", body: true, params: true, token: true },
    UpdateMealTypeLocale : { path: '/meal-types/edit/:id', method: "post", body: true, params: true, token: true },

    // meal styles
    ListMealStyles : { path: '/meal-styles/list', method: "get", token: true },
    CreateMealStyle : { path: '/meal-styles/add', method: "post", body: true, token: true },
    UpdateMealStyle : { path: '/meal-styles/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteMealStyle : { path: '/meal-styles/delete/:id', method: "get", params: true, token: true },
    GetMealStyleLocales : { path: '/meal-styles/locales/:id', method: "get", params: true, token: true },
    CreateMealStyleLocale : { path: '/meal-styles/add/:id', method: "post", body: true, params: true, token: true },
    UpdateMealStyleLocale : { path: '/meal-styles/edit/:id', method: "post", body: true, params: true, token: true },

    // serving sizes
    ListServingSizes : { path: '/serving-sizes/list', method: "get", token: true },
    CreateServingSize : { path: '/serving-sizes/add', method: "post", body: true, token: true },
    UpdateServingSize : { path: '/serving-sizes/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteServingSize : { path: '/serving-sizes/delete/:id', method: "get", params: true, token: true },
    GetServingSizeLocales : { path: '/serving-sizes/locales/:id', method: "get", params: true, token: true },
    CreateServingSizeLocale : { path: '/serving-sizes/add/:id', method: "post", body: true, params: true, token: true },
    UpdateServingSizeLocale : { path: '/serving-sizes/edit/:id', method: "post", body: true, params: true, token: true },


    // markets
    ListMarkets : { path: '/markets/list', method: "get", token: true },
    CreateMarket : { path: '/markets/add', method: "post", body: true, token: true },
    UpdateMarket : { path: '/markets/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteMarket : { path: '/markets/delete/:id', method: "get", params: true, token: true },
    GetMarketLocales : { path: '/markets/locales/:id', method: "get", params: true, token: true },
    CreateMarketLocale : { path: '/markets/add/:id', method: "post", body: true, params: true, token: true },
    UpdateMarketLocale : { path: '/markets/edit/:id', method: "post", body: true, params: true, token: true },

    // tags
    ListTags : { path: '/tags/list', method: "get", token: true },
    CreateTag : { path: '/tags/add', method: "post", body: true, token: true },
    UpdateTag : { path: '/tags/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteTag : { path: '/tags/delete/:id', method: "get", params: true, token: true },
    GetTagLocales : { path: '/tags/locales/:id', method: "get", params: true, token: true },
    CreateTagLocale : { path: '/tags/add/:id', method: "post", body: true, params: true, token: true },
    UpdateTagLocale : { path: '/tags/edit/:id', method: "post", body: true, params: true, token: true },

    // meals
    ListMeals : { path: '/meals/list', method: "get", token: true },
    GetMealLocales : { path: '/meals/locales/:id', method: "get", params: true, token: true },
    CreateMealLocale : { path: '/meals/add/:id', method: "post", body: true, params: true, token: true },
    UpdateMealLocale : { path: '/meals/edit/:id', method: "post", body: true, params: true, token: true },

    CreateMeal : { path: '/meals/add', method: "post", body: true, token: true },
    UpdateMeal : { path: '/meals/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteMeal : { path: '/meals/delete/:id', method: "get", params: true, token: true },

    // recipes
    ListRecipes : { path: '/recipes/list', method: "get", token: true },
    GetRecipeLocales : { path: '/recipes/locales/:id', method: "get", params: true, token: true },
    CreateRecipeLocale : { path: '/recipes/add/:id', method: "post", body: true, params: true, token: true },
    UpdateRecipeLocale : { path: '/recipes/edit/:id', method: "post", body: true, params: true, token: true },

    CreateRecipe : { path: '/recipes/add', method: "post", body: true, token: true },
    UpdateRecipe : { path: '/recipes/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteRecipe : { path: '/recipes/delete/:id', method: "get", params: true, token: true },

    // ingredients
    ListIngredients : {path: '/ingredients/list', method: "get", token: true },
    CreateIngredientLocale : { path: '/ingredients/add/:id', method: "post", body: true, params: true, token: true },
    UpdateIngredientLocale : { path: '/ingredients/edit/:id', method: "post", body: true, params: true, token: true },
    UsdaQuery : { path: '/ingredients/usda', method: "post", body: true, token: true },
    UsdaGet : { path: '/ingredients/usda/:id', method: "get", params: true, token: true },
    GetIngredientLocales : {path: '/ingredients/locales/:id', method: "get", params: true, token: true },

    CreateIngredient : { path: '/ingredients/add', method: "post", body: true, token: true },
    UpdateIngredient : { path: '/ingredients/edit/:id', method: "post", body: true, params: true, token: true },
    DeleteIngredient : { path: '/ingredients/delete/:id', method: "get",  params: true, token: true },

};
