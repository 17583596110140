<template>
  <div class="usda-form">
    <div v-if="ingredientName">
      <div class="form-field">
        <div class="form-input">
          <div class="diagonal-wrap wrap-bottom">
            <input :disabled="isDisabled" type="text" placeholder="Start typing to search Ingredients" @keyup="onSearch" />
          </div>
          <div class="diagonal-wrap" v-if="results.length > 0"><Button @click="toggleLegacy">{{'Try ' + (legacy?'Survey':'Legacy')}}</Button></div>
        </div>
      </div>
      <Scroller :height="250" v-if="results.length > 0">
        <BoxGroup :col="true" :small="true">
          <Box v-for="ingredient in results" :key="ingredient.id" :action="() => onSelectIngredient(ingredient)">{{ingredient.name}}</Box>
        </BoxGroup>
      </Scroller>
      <div v-if="sizes && id" class="usda-sizes">
        <div class="size second-main" v-for="(size, index) in sizes" :key="index">
          <p><input type="checkbox" :checked="selectedSizes.indexOf(index)>-1" @change="toggleSize(index)"></p>
          <input type="text" v-model="size.name" @change="resetSizes" />
          <p><b>{{size.amount_g}}</b>g</p>
        </div>
      </div>
    </div>
    <div v-if="!ingredientName" class="bolder">
      <p>Please enter the ingredient name to begin</p>
    </div>
  </div>
</template>

<script>

import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import {mapActions} from "vuex";
import Scroller from "@/components/ui/layout/Scroller";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import Button from "@/components/ui/form/Button";

let throttleTimeout;

export default {
  name: "UsdaForm",
  components: {Button, Box, BoxGroup, Scroller},
  props: ['tags','id', 'setSizes', 'ingredientName'],

  data() {
    return {
      newSearch : '',
      isDisabled: false,
      sizes: null,
      selectedSizes : null,
      legacy: false,
      results: []
    }
  },
  methods : {
    ...mapActions(['setLoading']),

    onSelectIngredient(ingredient) {
      this.$emit('select',ingredient)
      this.newSearch = ''
      this.results = []
    },

    onSearch(e) {
      this.results = []
      this.newSearch = e.target.value
      clearTimeout(throttleTimeout);
      throttleTimeout = setTimeout(() => {
        if (this.newSearch.length >= 3) this.doSearch(this.newSearch)
      }, 900)
    },

    async toggleLegacy() {
      this.legacy = !this.legacy;
      await this.doSearch(this.newSearch);
    },

    async doSearch(query) {
      await this.setLoading(true);
      this.isDisabled = true;
      if (this.legacy)
        this.results = (await ApiRequest(ApiCall(Api.UsdaQuery, { query, dataType: "SR+Legacy" }))).list
      else
        this.results = (await ApiRequest(ApiCall(Api.UsdaQuery, { query, source: "['SR+Legacy','Survey+(FNDDS)']" }))).list
      this.isDisabled = false;
      await this.setLoading(false);
    },
    addTag() {
      if (this.newTag) {
        this.$emit('add', this.newTag)
        this.newTag = ''
      }
    },
    toggleSize(index) {
      if (this.selectedSizes.indexOf(index) > -1) {
        this.selectedSizes.splice(this.selectedSizes.indexOf(index), 1)
      } else {
        this.selectedSizes.push(index)
      }

      this.resetSizes()
    },
    resetSizes() {
      const res = [];
      for (let r of this.selectedSizes) {
        res.push(this.sizes[r])
      }
      if (this.setSizes) this.setSizes(res);
    }
  },
  watch : {
    async id() {
      if (this.id) {
        await this.setLoading(true);
        const data = await ApiRequest(ApiCall(Api.UsdaGet, this.id))
        await this.setLoading(false);
        if (data.sizes) {
          this.sizes = {...data.sizes}
          this.selectedSizes = []
        }
      }
    }
  }
}
</script>
