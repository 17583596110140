<template>
  <textarea :placeholder="inputPlaceholder" :value="val" @keyup="e => onChange(e)" autocomplete="none" />
</template>

<script>
export default {
  name: "Textarea",
  props : {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
  },
  data() {
    return {
      val: ''
    }
  },
  mounted() {
    this.val = this.value;
  },
  computed : {
    inputPlaceholder() {
      return this.placeholder;
    }
  },
  methods : {
    onChange(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
