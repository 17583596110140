<template>
  <Container v-if="isLoggedIn">
    <ConfirmModal v-if="instanceForDeletion" item-type="Meal" :item-name="instanceForDeletion.name" :confirm="confirmDelete" :cancel="cancelDelete" />
    <FilteredItemList title="Meals" :fields="fields" :items="allMeals" :on-click="goto" :search="true" :filters="{'meal_style_id': 'Meal Style'}" :max-items="25"
                      :on-page="onPage" :page="page"
                      @delete="askToDelete" :meta="{meal_styles: filterMealStyles}" :actions="{'Add Meal': addNewMeal }" export-file="meals.csv" />
  </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {mealNutrition} from "@/lib/NutritionUtils";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import Container from "@/components/ui/layout/Container";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Icons from "@/lib/Icons";
import ListView from "@/mixins/views/ListView";

export default {
  name: "Meals",
  mixins: [ListView],
  components: {FilteredItemList, Container,  ConfirmModal },
  data() {
    return {
      type: "Meals",
      path: "meal",

      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true },
        {label: "", prop: "id", target:"errors", above: 1, primary: true, sortable: true, pill: 'error', format: (id) => this.mealErrors(id) },
        {label: "Style", prop: "meal_style_id", above: 1, width: "10%", sortable: true, format: (id) => this.mealStyle(id) },
        {label: "Rep. Group", prop: "rep_group", above: 3, sortable: true, format : it => it || '-'},
        {label: "Calories", prop: "id", target:"calories", above: 2, width:"70px", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'calories')},
        {label: "Protein (g)", prop: "id", target:"protein_g", above: 4, width:"80px", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'protein_g')},
        {label: "Fat (g)", prop: "id", target:"fat_g", width:"70px", above: 4, sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'fat_g')},
        {label: "% Protein", prop: "id", target:"protein_percent", above: 3, width:"80px", sortable: true, format : (meal_id) => this.rowPercentage(meal_id, 'protein_g', 4)},
        {label: "% Fat", prop: "id", target:"fat_percent", above: 3, width:"60px", sortable: true, format : (meal_id) => this.rowPercentage(meal_id, 'fat_g', 9)},
        {label: "Actions", prop: "", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allMeals.length, this.getMeals],
          [!this.allIngredients.length, this.getIngredients],
          [!this.allRecipes.length, this.getRecipes],
          [!this.allMealStyles.length, this.getMealStyles],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMeals','allIngredients','allRecipes','allMealStyles']),
    filterMealStyles() {
      return [{value: "-", name: "(No Meal Style)"}, ...this.allMealStyles].sort((a,b) => a.name<b.name?-1:1)
    }
  },

  methods: {
    ...mapActions(['getMeals','setLoading','deleteMeal','getIngredients','getRecipes','getMealStyles']),

    mealStyle(id) {
      const r = this.allMealStyles.find(it => it.id === id)
      return (r?r.name:'-')
    },
    mealErrors(id) {
      const target = this.allMeals.find(it => it.id === id);
      for (let ing of target.ingredients) {
        if (!ing.ingredient) return "Missing Ingredient"
      }
      return ''
    },

    rowNutrition(meal_id, prop) {
      if (this.allMeals) {
        const target = this.allMeals.find(it => it.id === meal_id);
        if (target) {
          const nutrition = mealNutrition(this.allMealIngredients(this.allMeals.find(it => it.id === meal_id)), false, this.allIngredients, true);
          return nutrition[prop];
        }
      }
    },

    rowPercentage(meal_id, prop, factor) {
      let res;
      if (this.allMeals) {
        const target = this.allMeals.find(it => it.id === meal_id);
        if (target) {
          const nutrition = mealNutrition(this.allMealIngredients(this.allMeals.find(it => it.id === meal_id)), false, this.allIngredients, true);
          res = (nutrition[prop] * factor / nutrition.calories * 100).toFixed(2);
        }

        return res;
      }
    },

    allMealIngredients(meal) {
      const ingredients = meal && meal.ingredients ? [...meal.ingredients] : []
      if (meal.recipes) {
        for (let recipe of meal.recipes) {
          if (recipe.recipe_id) {
            const rec = this.allRecipes.find(it => it.id === recipe.recipe_id);
            if (rec) {
              for (let ingredient of rec.ingredients) ingredients.push(ingredient)
            }
          }
        }
      }
      return ingredients
    },
  },

}
</script>
