export default {

    data() {
        return {
            // override these:
            async Delete() {},

            // these should be managed here:
            instanceForDeletion: null,
            search : "",
            page: 1,
            path: "unknown-path"
        }
    },

    mounted() {
        let page = (this.$route.hash.substr(1))
        if (!isNaN(page*1) && page*1 > 0) { this.page = page*1 }
    },

    computed : {
        // ...mapGetters(['allLocales','isLoggedIn']),
    },

    methods : {
        // ...mapActions(['setLoading','setSuccess','getLocales']),
        goto(meal) {
            this.$router.push('/'+this.path+'/'+meal.id)
        },
        addNewMeal() {
            this.$router.push('/'+this.path+'/new')
        },

        onPage(page) {
            if (page !== this.page) {
                this.page = page;
                this.$router.push({hash: page > 1 ? '#'+page : ''})
            }
        },
        askToDelete(meal) {
            this.instanceForDeletion = meal;
        },
        cancelDelete() { this.instanceForDeletion = null },
        async confirmDelete() {
            await this.Delete(this.instanceForDeletion.id)
            this.instanceForDeletion = null
        }
    },
    watch : {
        $route() {
            let page = (this.$route.hash.substr(1))
            if (!isNaN(page*1) && page*1 > 0) { this.page = page*1 }
            else this.page = 1;
        }
    }
}
