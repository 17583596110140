<template>
  <div :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    full: Boolean
  },
  computed: {
    className() {
      let cls = 'container';
      if (this.full) cls += ' full'
      return cls.trim()
    }
  }
}
</script>
