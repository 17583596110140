<template>
    <Container>
      <ConfirmModal v-if="userForDeletion" item-type="User" :item-name="userForDeletion.username" :confirm="confirmDeleteUser" :cancel="cancelDeleteUser" />
      <FilteredItemList title="Users" :fields="fields" :items="allUsers" :on-click="goto" :search="true" @delete="askToDeleteUser" :actions="{'Add User': addNewUser }" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import Container from "@/components/ui/layout/Container";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Icons from "@/lib/Icons";

export default {
  name: "Users",
  components: {FilteredItemList, Container,  ConfirmModal },

  data() {
    return {
      userForDeletion: null,
      search : "",
      fields : [
        {label: "Username", prop: "username", primary: true, sortable: true},
        {label: "Role", prop: "role_id", sortable: true, format: (id) => { const role = this.allUserRoles.find(it => it.id === id); return role ? role.name : '-'  } },
        {label: "Locale", prop: "locale_id", above: 1, sortable: true, format: (id) => {const locale = this.allLocales.find(it => it.id === id);  return locale ? locale.description : '-'  } },
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  computed: mapGetters(['isLoggedIn', 'allUsers', 'allUserRoles', 'allLocales']),

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
        [!this.allUsers.length, this.getUsers],
        [!this.allUserRoles.length, this.getUserRoles],
        [!this.allLocales.length, this.getLocales],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getUsers','getUserRoles','setLoading','deleteUser', 'getLocales']),
    goto(user) {
      this.$router.push('/user/'+user.id)
    },
    addNewUser() {
      this.$router.push('/user/new')
    },
    askToDeleteUser(user) {
      this.userForDeletion = user;
    },
    cancelDeleteUser() { this.userForDeletion = null },
    confirmDeleteUser() {
      this.deleteUser(this.userForDeletion.id)
      this.userForDeletion = null
    }
  },
}
</script>
