import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        ingredients : [],
    },
    getters : {
        allIngredients: state => state.ingredients,
    },
    actions : {
        async getIngredients({commit}) {
            const ingredients = await ApiRequest(ApiCall(Api.ListIngredients));
            return await commit('setIngredients', ingredients);
        },
        async updateIngredient({commit}, [ingredient, id]) {
            await ApiRequest(ApiCall(Api.UpdateIngredient, ingredient, id))
            return await commit('saveIngredient', [ingredient, id])
        },
        async createIngredient({commit}, ingredient) {
            const res = await ApiRequest(ApiCall(Api.CreateIngredient, ingredient))
            ingredient.id = res.insertId
            return await commit('saveIngredient', [ingredient, null])
        },
        async deleteIngredient({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteIngredient, id))
            return await commit('deleteIngredient', id)
        },
    },
    mutations : {
        setIngredients: (state, ingredients) => state.ingredients = ingredients,
        saveIngredient: (state, [ingredient, id]) => {
            if (id) {
                state.ingredients = state.ingredients.map(it => it.id === ingredient.id ? ingredient : it)
            }
            else {
                state.ingredients = [...state.ingredients, ingredient];
            }
            return state
        },
        deleteIngredient(state, id) {
            state.ingredients = state.ingredients.filter(it => it.id !== id)
        },
    }
}
