<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :on-update="onUpdate" :on-submit="onSubmit" />
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";

export default {
  name: "Locale",
  mixins: [ InstanceView ],
  components: {InstanceDash,},
  data() {
    return {
      type: "Locale",
      path: "locale",
      fields : [
        Fe.Text("name", "Name"),
        Fe.Textarea("description", "Description"),
      ],
      Create : this.createLocale,
      Update : this.updateLocale,
      defaultData: { name: "New Locale", slug: "" },
    }
  },

  async mounted() {
    await this.initInstance('allLocales',[!this.allLocales.length, this.getLocales])
  },
  computed: mapGetters(['allLocales']),
  methods: mapActions(['getLocales','updateLocale','createLocale']),
}
</script>
