import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import {setToken} from "@/lib/Storage";

export default {
    state : {
        user : null,
        token: null,
        loginError: null,
        isLoading: false,
        error: '',
        success : '',
    },
    getters : {
        isLoggedIn : (state) => !!state.user,
        loginError : state => state.loginError,
        appError : state => state.error,
        currentUser : state => state.user,
        isLoading : state => state.isLoading,
        success : state => state.success
    },
    actions : {

        async login({commit}, [username, password]) {
            await commit('resetLoginErrors')
            const res = await ApiRequest(ApiCall(Api.Login, {username, password}));
            if (res.token) {
                setToken(res.token);
                await commit('loginSuccess', {user: res.user, token: res.token })
            }
            else await commit('loginError', res.error)
            return true
        },

        async logout({commit}) {
            await ApiRequest(ApiCall(Api.Logout));
            return await commit('logout');
        },

        async checkLogin({commit}) {
            const res = await ApiRequest(ApiCall(Api.CheckLogin));
            if (res.user) {
                await commit('loginSuccess', {user: res.user, token: null })
            }
            return true
        },

        async setLoading({commit}, isLoading) {
            if (isLoading) await commit('loading')
            else await commit('loaded')
            return true
        },

        async setSuccess({commit}, success) {
            await commit('success', success)
            if (success) {
                setTimeout(async () => await commit('clearSuccess'), 3000)
            }
            return true
        },
        async setError({commit}, error) {
            await commit('setError', error)
            if (error) {
                setTimeout(async () => await commit('clearError'), 5000)
            }
            return true
        }

    },
    mutations : {
        resetLoginErrors: (state) => { state.loginError = null },
        loginSuccess: (state, {user, token}) => {
            if (token) state.token = token
            state.user = user
        },
        loginError: (state, loginError) => {
            state.loginError = loginError;
            state.user = state.token = null;
        },
        setError: (state, error) => {
            console.log({error})
            state.error = error;
        },
        clearError: state => state.error = '',
        logout : (state) => { state.user = state.token = null; },
        loading: (state) => { state.isLoading = true; },
        loaded: (state) => { state.isLoading = false; },
        success: (state, success) => { state.success = success; },
        clearSuccess : state => state.success = '',
    }
}
