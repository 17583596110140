<template>
  <Scroller :full="true">
    <Content :flex="1" :col="true">
      <Pagination :total="items.length" :page="page" @change="onPage" v-if="maxItems && items.length > maxItems" />
      <table v-if="sortedItems.length" :class="'list' + (onClick?' interactive':'')">
        <thead>
        <tr>
          <th v-for="(field, index) in fields" :key="index" :width="field.width?field.width:'auto'" :class="headCellClass(field)" @click="headClick(field.sortable, field)">
            <span>{{field.label}}</span>
            <span v-if="sortBy === (field.target?field.target:field.prop)" :class="'sort-by-field' + (sortDir<0?' desc':'')"></span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in sortedItems" :key="index" @click="doClick(item)">
          <td v-for="(field, fIndex) in fields" :key="fIndex" :class="visibilityClass(field, item)">
            <component :is="itemTag(field)" :class="itemClass(field, item)" :title="itemTitle(field, item)" :style="itemStyle(field, item)">{{itemContent(field, item)}}</component>
            <RowActions :actions="field.actions" :item="item" v-on="actionBinding(item, index)" />
          </td>
        </tr>
        </tbody>
      </table>
      <Box v-if="sortedItems.length === 0" line="true" no-border="true">
        <Txt>No items were found..</Txt>
      </Box>
    </Content>
  </Scroller>
</template>

<script>
import Txt from "@/components/ui/utils/Txt";
import Box from "@/components/ui/layout/Box";
import Panel from "@/components/ui/layout/Panel";
import Badge from "@/components/ui/utils/Badge";
import Scroller from "@/components/ui/layout/Scroller";
import Icon from "@/components/ui/utils/Icon";
import FormattedList from "@/mixins/FormattedList";
import RowActions from "@/components/ui/lists/RowActions";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Content from "@/components/ui/layout/Content";
import Pagination from "@/components/ui/lists/Pagination";
export default {
  name: "ItemList",
  mixins: [FormattedList],
  components: {Pagination, Content, BoxGroup, RowActions, Icon, Scroller, Badge, Panel, Box, Txt},
  data() {
    return {
      values : {},
      sortBy: null,
      sortDir: 1
    }
  },

  props: {
    actions: Array,
    noFormatting: Boolean,
    onClick: Function,
    onPage: {type: Function, default : () => {console.log("akakak")}},
    maxItems : {type: Number, default: null},
    page : {type: Number, default: 1},
  },

  computed : {
    sortedItems() {
      let items = this.items;
      if (!items) return []
      items = items.sort((a,b) => ((isNaN(a[this.sortBy]*1) ? a[this.sortBy] < b[this.sortBy] : a[this.sortBy]*1 < b[this.sortBy]*1)?-1:1))
      if (this.sortDir === -1) items = items.reverse()
      if (this.maxItems) items = items.slice(this.maxItems * (this.page-1), this.maxItems * (this.page-1) + this.maxItems);
      return items;
    }
  },

  methods: {

    headCellClass(field) {
      let cls = '';
      if (field.primary) cls += 'primary'
      if (field.sortable) cls += ' sortable'
      if (field.above) cls += ' above_'+field.above;
      if (field.below) cls += ' below_'+field.below;
      return cls.trim()
    },

    headClick(sortable, field) {
      if (!sortable) return false;
      const prop = field.target?field.target:field.prop;

      if (this.sortBy === prop) this.sortDir = -this.sortDir
      else {
        this.sortBy = prop;
        this.sortDir = 1
      }
    },

    async doClick(item) {
      if (this.onClick) await this.onClick(item)
    },

  },

}
</script>

