<template>
  <div id="app">
    <Navbar :links="navbarLinks" />
    <router-view />
  </div>
</template>

<script>

import Navbar from "@/components/ui/navigation/Navbar";
import {mapActions} from "vuex";
import 'vue-select/dist/vue-select.css';
import './scss/style.scss';


export default {
  name: 'App',
  components: {Navbar},
  data() {
    return {
      navbarLinks : [
        {to: '/users', label: 'Users'},
        {to: '/roles', label: 'Roles'},
        {to: '/snapshots', label: 'Snapshots'},
        {to: '/locales', label: 'Locales'},
        {to: '/meal-types', label: 'Meal Types'},
        {to: '/meal-styles', label: 'Meal Styles'},
        {to: '/ingredients', label: 'Ingredients'},
        {to: '/recipes', label: 'Recipes'},
        {to: '/markets', label: 'Markets'},
        {to: '/serving-sizes', label: 'Serving Sizes'},
        {to: '/tags', label: 'Tags'},
      ]
    }
  },
  methods : {
    ...mapActions(['checkLogin'])
  },
  mounted() {
    this.checkLogin()
  }
}
</script>
