<template>
    <Container>
      <ConfirmModal v-if="tagForDeletion" item-type="Tag" :item-name="tagForDeletion.tag" :confirm="confirmDeleteTag" :cancel="cancelDeleteTag" />
      <FilteredItemList title="Tags" :fields="fields" :items="allTags" :on-click="goto" :search="true" @delete="askToDeleteTag" :actions="{'Add Tag': addNewTag }" />
    </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "Tags",
  components: {Container, FilteredItemList, ConfirmModal },

  data() {
    return {
      tagForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px"},
        {label: "Tag", prop: "tag", primary: true, sortable: true},
        {label: "Type", prop: "type", width: "10%", sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allTags']),
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allTags.length, this.getTags]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getTags','setLoading','deleteTag']),
    goto(tag) {
      this.$router.push('/tag/'+tag.id)
    },
    addNewTag() {
      this.$router.push('/tag/new')
    },
    askToDeleteTag(tag) {
      this.tagForDeletion = tag;
    },
    cancelDeleteTag() { this.tagForDeletion = null },
    confirmDeleteTag() {
      this.deleteTag(this.tagForDeletion.id)
      this.tagForDeletion = null
    }
  },
}
</script>
