<template>
  <div :class="className">
    <slot></slot>
  </div>
</template>

<script>
import Flexer from "@/mixins/Flexer";

export default {
  name: "Content",
  mixins: [Flexer],

  data() {
    return {
      clsName: 'content',
      inheritsFlexyViaSass: true,
    }
  },

}
</script>
