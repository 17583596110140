<template>
    <component :is="tagName" :class="className" :to="routerLink" :href="link" @click="emitClick">
      <slot></slot>
    </component>
</template>

<script>
export default {
  name: "Button",
  props: {
    mood: {type: String, default: null},
    link: {type: String, default: null},
    routerLink: {type: String, default: null},
    align: {type: String, default: "center"},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    tagName() {
      if (this.link) return 'a';
      if (this.routerLink) return 'router-link';
      return 'span';
    },
    className() {
      let cls = 'button';
      switch (this.mood) {
        case 'success': cls += ' success'; break;
        case 'warning': cls += ' warning'; break;
        case 'danger': cls += ' danger'; break;
      }
      switch (this.align) {
        case 'right': cls += ' right'; break;
        case 'left': cls += ' left'; break;
        case 'center': cls += ' center'; break;
      }

      if (this.disabled) cls += ' disabled';

      return cls.trim()
    }
  },
  methods : {
    emitClick(e) { this.$emit('click', e)}
  }
}
</script>
