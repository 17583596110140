<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :title-prop="titleProp"
                :on-update="onUpdate" :on-submit="onSubmit"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale">
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import tagFields from "@/lib/form-fields/tag";
import Api from "@/lib/Api";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";

export default {

  name: "Tag",
  components: {InstanceDash },

  mixins: [InstanceView],

  data() {
    return {
      type: "Tag",
      path: "tag",
      titleProp: "tag",
      fields : tagFields,
      Create : this.createTag,
      Update : this.updateTag,
      defaultData: {
        name: "New Tag",
      },
      locale : { get: Api.GetTagLocales, create : Api.CreateTagLocale, update: Api.UpdateTagLocale },
      localeFields: [{ prop:"tag" , label: "Tag", type: "text" }],
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn','allTags','allLocales']),
  },

  async mounted() {
    await this.initInstance('allTags',[
      [!this.allTags.length, this.getTags],
      [!this.allLocales.length, this.getLocales],
    ])
  },

  methods: {
    ...mapActions(['getTags','setLoading','setSuccess','getLocales','updateTag','createTag']),
  },

}
</script>
