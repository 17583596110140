<template>
  <div class="modal" @click="() => cancel && cancel()">
    <div class="modal-inner" @click="(e) => e.stopPropagation()">
      <h3 v-if="title">{{title}}</h3>
      <div class="modal-content">
        <slot></slot>
      </div>
      <ButtonGroup>
        <Button v-for="(action, name) in actions" :key="name" @click="action">{{name}}</Button>
        <Button v-if="cancel" @click="cancel">Cancel</Button>
      </ButtonGroup>
    </div>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ui/layout/ButtonGroup";
import Button from "@/components/ui/form/Button";
export default {
  name: "Modal",
  components: {Button, ButtonGroup},
  props: {
    title: String,
    cancel: Function,
    actions: Object,
  }
}
</script>
