<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields"
                :on-update="onUpdate" :on-submit="onSubmit" :meta="{Units}"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale">
    <template slot="sidebar" v-if="instance">
      <Box title="USDA Search">
        <p>Search and import USDA Survey nutrients data</p>
        <UsdaForm @select="onUsdaValues" :id="usdaId" :set-sizes="onSetSizes" :ingredient-name="instance&&instance.name" />
      </Box>
      <Box v-if="instance.id" title="Tags">
        <TagList :all-tags="allTags" tag-key="tag_id" :tags="instance.tags" prop="tag" @remove-tag="onRemoveTag" />
        <AddTagForm :tags="allTags" @add="onAddTag" :multi="true" />
      </Box>
      <Box v-if="instance.id" title="Serving Sizes">
        <TagList :all-tags="allServingSizes" tag-key="serving_size_id"
                 :tags="instance.serving_sizes" prop="name" secondary="amount_g" secondary-suffix="g" @remove-tag="onRemoveSize" />
        <ServingSizeForm :serving-sizes="ingredientServingSizes" :existing-sizes="instance.serving_sizes" @update="onServingSizes" @add="onAddSize" />
      </Box>
      <Box v-if="instance.id" title="Markets">
        <TagList :all-tags="allMarkets" tag-key="market_id" :tags="instance.markets" prop="name" @remove-tag="onRemoveMarket" />
        <AddTagForm :tags="allMarkets" label="name" placeholder="Select a Market" @add="onAddMarket" />
      </Box>
    </template>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ingredientFields from "@/lib/form-fields/ingredient";
import UsdaForm from "@/components/forms/UsdaForm";
import TagList from "@/components/ui/lists/TagList";
import AddTagForm from "@/components/forms/AddTagForm";
import ServingSizeForm from "@/components/forms/AddServingSizeForm";
import Api from "@/lib/Api";
import Box from "@/components/ui/layout/Box";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Units from "@/lib/Units";
export default {

  name: "Ingredient",
  mixins: [InstanceView],
  components: { InstanceDash,Box, UsdaForm, TagList, AddTagForm, ServingSizeForm },

  data() {
    return {
      Units,
      type: "Ingredient",
      path: "ingredient",
      fields : ingredientFields,
      Create : this.createIngredient,
      Update : this.updateIngredient,
      defaultData: {
        name: "New Ingredient",
        description: "",
      },
      locale : { get: Api.GetIngredientLocales, create : Api.CreateIngredientLocale, update: Api.UpdateIngredientLocale },
      localeFields : [ {prop: "name", label:"Name", type:"text"}, {prop: "instructions", label:"Instructions", type:"textarea"}, ],
      ingredientKey : "ingredient_id",

      usdaSizes : null,
      usdaId : null,
    }
  },
  async mounted() {
    await this.initInstance('allIngredients',[
      [!this.allIngredients.length, this.getIngredients],
      [!this.allTags.length, this.getTags],
      [!this.allServingSizes.length, this.getServingSizes],
      [!this.allMarkets.length, this.getMarkets],
    ])

  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allIngredients', 'allTags','allServingSizes','allMarkets']),
    ingredientServingSizes() {
      return this.allServingSizes
    }
  },

  methods: {
    ...mapActions(['getIngredients','getTags','setLoading','setSuccess','getServingSizes','getMarkets','updateIngredient','createIngredient','createServingSize']),

    async doSubmit() {
      await this.onSubmit()

      if (this.usdaSizes) {
        await this.createServingSizes(this.instance.id, this.usdaSizes);
        this.usdaSizes = this.usdaId = null;
      }
    },

    async createServingSizes(ingredient_id, sizes) {
      let servingSize;
      for (let size of sizes) {
        size.name = this.instance.name + ' - '+size.name;
        servingSize = await this.createServingSize(size)
        this.instance.serving_sizes.push({amount_g: servingSize.amount_g, serving_size_id: servingSize.id, ingredient_id})
      }
      await this.updateIngredient([this.instance, this.instance.id])
    },

    async onUsdaValues(ingredient) {
      this.instance = {...this.instance, nutrition: {...this.instance.nutrition, ...ingredient.nutrients }}
      this.usdaId = ingredient.id;
    },

    onSetSizes(sizes) { this.usdaSizes = sizes; },
    onServingSizes(servingSizes) { this.instance.serving_sizes = servingSizes; },

    onAddTag(tagId) {
      let tags = tagId instanceof Array ? tagId : [tagId]
      tags = tags.map(tagId => ({tag_id: tagId, ingredient_id: this.instance.id || null}))
      let tag
      for (tag of tags) {
        this.instance.tags.push(tag)
      }
      console.log(this.instance.tags)
      // if (tag) {
      //   this.instance.tags.push({tag_id : tag.id, ingredient_id: this.instance.id || null})
      // }
    },

    onAddMarket(marketId) {
      const market = this.allMarkets.find(it => it.id === marketId)
      if (market) {
        this.instance.markets.push({market_id : marketId, ingredient_id: this.instance.id || null})
      }
    },

    onAddSize(sizeId, amountG) {
      const size = this.allServingSizes.find(it => it.id === sizeId)
      size.amount_g = amountG
      if (size) {
        this.instance.serving_sizes.push({serving_size_id: sizeId, amount_g: amountG, ingredient_id: this.instance.id || null})
      }
    },

    onRemoveTag(id) { console.log({id}); this.instance.tags = this.instance.tags.filter(it => it.tag_id !== id) },
    onRemoveMarket(id) { this.instance.markets = this.instance.markets.filter(it => it.id !== id) },
    onRemoveSize(id) {
      // console.log(this.instance.serving_sizes, id)
      this.instance.serving_sizes = this.instance.serving_sizes.filter(it => it.id !== id)
    },
  },
}
</script>
