<template>
  <Container>
    ayy..
  </Container>
</template>

<script>
import Container from "@/components/ui/layout/Container";
export default {
  name: "Problems",
  components: {Container}
}
</script>

<style scoped>

</style>
