<template>
  <BoxGroup :col="false" :small="true">
    <Box v-if="page > 1" :action="() => onPage(1)">&lt;&lt;</Box>
    <Box v-if="page > 1" :action="() => onPage(page-1)">&lt;</Box>
    <Box v-for="pp in prevPages" :key="pp" :action="() => onPage(pp)">{{pp}}</Box>
    <Box><Txt :bold="true">{{page}}</Txt></Box>
    <Box v-for="np in nextPages" :key="np" :action="() => onPage(np)">{{np}}</Box>
    <Box v-if="page < totalPages-1" :action="() => onPage(page+1)">&gt;</Box>
    <Box v-if="page < totalPages-1" :action="() => onPage(totalPages)">&gt;&gt;</Box>
  </BoxGroup>
</template>

<script>
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "Pagination",
  components: {Txt, Box, BoxGroup},
  props: {
    total: Number,
    page : {type: Number, default: 1},
    limit : {type: Number, default: 25},
    before : {type: Number, default: 4},
    after : {type: Number, default: 4},
  },

  computed : {
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
    prevPages() {
      let pages = [], max = this.before;
      for (let i = this.page-1; i >= 1 && max > 0; i--, max--) {
        pages.push(i)
      }
      pages = pages.reverse()
      return pages;
    },
    nextPages() {
      let pages = [], max = this.after
      for (let i = this.page+1; i <= this.totalPages && max > 0; i++, max--) {
        pages.push(i)
      }
      return pages;
    }
  },

  methods : {
    onPage(page) {
      this.$emit('change', page);
    }
  }
}
</script>
