<template>
  <Content :col="col">
    <FormField v-for="(field,index) in fields" :key="index" :field="field" :value="val(field.prop, item)" @change="value => update(field.prop, value)" :meta="meta" :show="showCondition(field)" :no-labels="noLabels" :filter-options="field.filterOptions?filterOptions(field):null" />
    <Content :col="true">
      <Txt v-if="!noLabels">&nbsp;</Txt>
      <Button v-if="cancel" @click="cancel">{{cancelLabel}}</Button>
      <Button :disabled="!isValid" @click="doSubmit">{{submitLabel}}</Button>
    </Content>
  </Content>
</template>

<script>
import Content from "@/components/ui/layout/Content";
import FormField from "@/components/ui/form/FormField";
import {propDeep} from "@/lib/ui/UiUtils";
import Button from "@/components/ui/form/Button";
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "InlineForm",
  props : {
    noLabels: Boolean,
    col: {type: [Boolean, Object], default: false},
    submitLabel : {type: String, default: "Submit"},
    cancelLabel: {type: String, default: "Cancel"},
    fields : Array,
    validate : {type: Function, default: () => true},
    onSubmit : {type: Function, default: () => {}},
    cancel: {type: Function, default: null},
    item : { type: Object, default: () => {} },
    meta : { type: Object, default: () => {} },
  },

  components: {Txt, Button, FormField, Content},

  data() {
    return {
      values : {}
    }
  },

  mounted() {
    this.values = (this.item) ? {...this.item} : {}
  },

  computed : {
    isValid() {
      return (this.validate) ? this.validate() : true
    },
  },

  methods : {
    filterOptions(field) {
      if (!field.filterOptions) return (options) => options;
      return (options) => {
        return field.filterOptions(options, this.values);
      }
    },
    val(prop, data) {
      return propDeep(data, prop)
    },

    showCondition(field) {
      if (typeof field.if === "function") {
        return field.if(this.values)
      }
      return true
    },

    update(prop, data) {
      let p = prop.split(/\./g), values = propDeep(this.values, prop, data)
      this.$set(this.values, p[0], values[p[0]]);

      this.$emit('update', this.values);
    },

    doSubmit() {
      if (!this.isValid) return;
      if (this.onSubmit) this.onSubmit(this.values)
    }
  },
  watch : {
    item() {
      this.values = (this.item) ? {...this.item} : {}
    }
  }
}
</script>
