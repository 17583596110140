<template>
  <Panel>
  <div class="locale-form">
    <div class="locales" v-if="!settingLocale">
      <ButtonGroup :col="true">
        <Button v-for="locale in allLocales" :key="locale.id" @click="settingLocale = locale">
          <Flexy :flex="1" :between="true">
            <Badge :mood="(locales?(hasLocale(locale)?'success':'danger'):'disabled')" />
            <Txt>{{locale.description}}</Txt>
            <div v-if="hasLocale(locale)" class="locale-inner"><span class="bold">{{getLocale(locale)[nameField || 'name']}}</span>
            <small v-if="secondary">{{getLocale(locale)[secondary]}}</small></div>
          </Flexy>
        </Button>
      </ButtonGroup>
    </div>
    <div v-if="settingLocale">
      <InlineForm :col="true" :fields="fields" :item="getLocale(settingLocale)" :on-submit="doSubmit" :cancel="() => settingLocale = false" />
    </div>
  </div>
  </Panel>
</template>

<script>
import Panel from "@/components/ui/layout/Panel";
import Badge from "@/components/ui/utils/Badge";
import Txt from "@/components/ui/utils/Txt";
import ButtonGroup from "@/components/ui/layout/ButtonGroup";
import Button from "@/components/ui/form/Button";
import Flexy from "@/components/ui/layout/Flexy";
import InlineForm from "@/components/ui/form/InlineForm";
export default {
  name: "LocaleForm",
  props: ['onSubmit','locales','allLocales','fields','nameField','secondary'],
  components: {InlineForm, Flexy, Button, ButtonGroup, Txt, Badge, Panel},
  data() {
    return {
      settingLocale: null
    }
  },
  methods : {
    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },
    getLocale(locale) {
      return this.locales && this.locales.find(it => it.locale_id === locale.id)
    },

    doSubmit(data) {
      this.onSubmit(data, this.settingLocale.id)
      this.settingLocale = null
    }
  }
}
</script>
