import Txt from "@/components/ui/utils/Txt";
import Badge from "@/components/ui/utils/Badge";
import Icon from "@/components/ui/utils/Icon";

export default {
    props : {
        items: {type: Array, default: [] },
        fields: {type: Array, default: [] },
        preformatted:{type:Boolean, default: false},
        itemType: {type: String, default: "item" },
    },

    computed : {
        allActions() {
            let actions = {}
            for (let f of this.fields) if (f.actions) {
                for (let a of f.actions) if (a.action) actions[a.action] = true
            }
            return Object.keys(actions);
        },
    },

    methods : {
        actionBinding(item, index) {
            const res = {}
            for (let a of this.allActions) {
                res[a] = () => {
                    this.doAction(a, item, index)
                }
            }
            return res
        },
        itemTag(field) {
            if (field.type === 'image') return 'img'
            if (field.type === 'icon') return Icon
            else if (field.type === 'boolean') return Badge
            else if (field.type === 'badge') return Badge
            return Txt
        },

        itemIcon(field, item) {
            return this.val(field, item, field.format)
        },

        itemClass(field, item) {
            if (field.type === 'boolean') {
                const res = item[field.target?field.target:field.prop]
                if (res) { if (!field.negative || field.always) return 'badge success' }
                else { if (field.negative || field.always) return 'badge danger' }
            }
            if (field.type === 'color') return 'badge'
            if (field.bold) {
                if (typeof field.bold === "function") return field.bold(item[field.target?field.target:field.prop], item)?'bold':''
                else return 'bold'
            }
            if (field.primary) return 'bold'
        },

        itemStyle(field, item) {
            if (field.type === 'color') {
                return {background: this.val(field, item)}
            }
            else if (field.type === 'image' && field['imageBackground']) {
                return {background: item[field['imageBackground']]}
            }
            else if (field.type === 'boolean') {
                return {display: field.always || (this.val(field, item) || field.negative)?'':'none'}
            }
        },

        itemContent(field, item) {
            const val = this.val(field, item, field.format);
            if (field.type === 'image' || field.type === 'color') return ''
            if (field.type === 'boolean') {
                if (field.labelTrue && val) return field.labelTrue
                if (field.labelFalse && !val) return field.labelFalse
                return ''
            }
            return val;
        },

        doAction(action, item, data = null) {
            this.$emit(action, item, data)
            return false;
        },

        visibilityClass(field, item) {
            let cls = '';
            if (field.primary) cls += 'primary'
            if (field.above) cls += ' above_'+field.above;
            if (field.below) cls += ' below_'+field.below;
            if (typeof field.if === "function") {
                if (!field.if(item[field], item)) cls += ' hidden'
            }
            return cls.trim()
        },

        itemTitle(field, item) {
            if (field.type === 'boolean') return item[field.target?field.target:field.prop] ? 'Yes':'No'
        },

        val(field, data, format = null) {
            if (this.noFormatting) return data[field.target?field.target:field.prop];
            if (!field.prop) return null;
            let v, p = field.prop.split(/\./g), res = data;
            while (p && p.length > 0) {
                v = p.shift();
                if (!res[v]) return null;
            }
            res = format && !this.preformatted ? format(res[v], res) : res[field.target?field.target:field.prop]
            if (typeof res === 'string' && field.shorten && res.length > field.shorten) return res.substr(0, field.shorten)+'...'
            return res;
        },
    }
}
