<template>
  <div :class="className">
    <slot></slot>
  </div>
</template>

<script>

import Flexer from "@/mixins/Flexer";

export default {
  name: "BoxGroup",
  mixins: [Flexer],
  props: {
    noBorder: Boolean,
    noExternalBorder: Boolean,
    small: Boolean,
    col: {
      type: [Boolean, Object],
      default: true
    }
  },
  data() {
    return {
      clsName: 'box-group',
      inheritsFlexyViaSass: true,
      classIfs: [
          [this.noBorder, 'no-border'],
          [this.noExternalBorder, 'no-external-border'],
          [this.small, 'small'],
      ]
    }
  },
}
</script>
