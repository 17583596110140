<template>
    <Container>
      <ConfirmModal v-if="ingredientForDeletion" item-type="Ingredient" :item-name="ingredientForDeletion.name" :confirm="confirmDeleteIngredient" :cancel="cancelDeleteIngredient" />
      <FilteredItemList title="Ingredients" :fields="fields" :items="allIngredients" :on-click="goto" :search="true" @delete="askToDeleteIngredient" :actions="{'Add Ingredient': addNewIngredient }" export-file="ingredients.csv" />
    </Container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";
import Units from "@/lib/Units";

export default {
  name: "Ingredients",
  components: {Container, FilteredItemList, ConfirmModal },
  data() {
    return {
      ingredientForDeletion: null,
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true },
        {label: "Slug", above: 1, prop: "slug", sortable: true, width: "10%" },
        {label: "Unit", above: 1, prop: "unit_type", sortable: true, width: "10%", format: this.unitType },
        {label: "Actions", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allIngredients.length, this.getIngredients]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allIngredients']),
  },

  methods: {
    ...mapActions(['getIngredients','setLoading','deleteIngredient']),
    goto(ingredient) {
      this.$router.push('/ingredient/'+ingredient.id)
    },
    unitType(type) {
      const u = Units.find(it => it.short === type);
      return u ? u.name : type
    },
    addNewIngredient() {
      this.$router.push('/ingredient/new')
    },
    askToDeleteIngredient(ingredient) {
      this.ingredientForDeletion = ingredient;
    },
    cancelDeleteIngredient() {
      this.ingredientForDeletion = null
    },
    confirmDeleteIngredient() {
      this.deleteIngredient(this.ingredientForDeletion.id)
      this.ingredientForDeletion = null
    }
  },

}
</script>
