<template>

  <InstanceDash :type="type" :instance="instance" :fields="fields"
                :on-update="onUpdate" :on-submit="onSubmit"
                :all-locales="allLocales" :locales="locales" :locale-fields="localeFields" :set-locale="setLocale">
    <template slot="main">
      <DraggableList title="Ingredients" :items="sortedIngredients" :fields="ingredientFields" item-type="ingredient" @delete="onDeleteIngredient" @sort="onSortIngredient" @edit="editIngredient" />
      <AddIngredientForm @submit="onAddIngredient" :recipe="true" :data="selectedIngredient" />
    </template>
    <Box slot="sidebar" v-if="instance && instance.id && instance.ingredients && instance.ingredients.length">
      <NutritionCounter :ingredients="sortedIngredients" />
    </Box>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import recipeFields from "@/lib/form-fields/recipe";
import NutritionCounter from "@/components/ui/stats/NutritionCounter";
import Box from "@/components/ui/layout/Box";
import DraggableList from "@/components/ui/lists/DraggableList";
import InstanceView from "@/mixins/views/InstanceView";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import IngredientListView from "@/mixins/views/IngredientListView";
import Api from "@/lib/Api";
import AddIngredientForm from "@/components/forms/AddIngredientForm";

export default {

  name: "Recipe",
  mixins: [InstanceView, IngredientListView],
  components: {AddIngredientForm, InstanceDash, DraggableList, Box, NutritionCounter },
  data() {
    return {
      type: "Recipe",
      path: "recipe",
      fields : recipeFields,
      Create : this.createRecipe,
      Update : this.updateRecipe,
      defaultData: {
        name: "New Recipe",
        description: "",
        ingredients: []
      },
      locale : { get: Api.GetRecipeLocales, create : Api.CreateRecipeLocale, update: Api.UpdateRecipeLocale },
      localeFields : [ {prop: "name", label:"Name", type:"text"}, {prop: "description", label:"Description", type:"textarea"} ],
      ingredientKey : "recipe_id",
    }
  },

  async mounted() {
    await this.initInstance('allRecipes',[!this.allRecipes.length, this.getRecipes])
  },

  computed: mapGetters(['isLoggedIn', 'allRecipes', 'allIngredients', 'allServingSizes']),
  methods: mapActions(['getRecipes', 'setLoading','setSuccess','updateRecipe','createRecipe']),
}
</script>
