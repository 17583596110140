<template>
  <span :class="className" @click="(e) => $emit('click', e)">
    <a v-if="link" :href="link" :class="mood"><slot></slot></a>
    <router-link v-if="routerLink" :to="routerLink"><slot></slot></router-link>
    <slot v-if="!link && !routerLink"></slot>
  </span>
</template>

<script>
export default {
  name: "Badge",
  props: ['mood', 'link', 'routerLink', 'outline','interactive'],
  computed: {
    className() {
      let cls = 'badge';
      switch (this.mood) {
        case 'primary': cls += ' primary'; break;
        case 'success': cls += ' success'; break;
        case 'warning': cls += ' warning'; break;
        case 'danger': cls += ' danger'; break;
      }
      if (this.outline) cls += ' outline';
      if (this.interactive) cls += ' interactive';
      return cls.trim()
    }
  }
}
</script>
